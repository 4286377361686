import { InputGroup, Input, InputRightElement, Button, useDisclosure, InputLeftElement, Box, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, OrderedList, ListItem, Select } from "@chakra-ui/react";
import { ReactChild, ReactFragment, ReactPortal, useEffect, useState } from "react";
import {
    FiMenu as HamburgerIcon,
    FiX as CloseIcon,
    FiChevronDown as ChevronDownIcon,
    FiChevronUp as ChevronRightIcon,
    FiSearch
} from 'react-icons/fi';
import { CityModel } from "src/models/state.model";
import Category from "src/store/category";
import State from "src/store/state";
import { createStylesheet } from "src/utils/style";
import Autosuggest from 'react-autosuggest';
import postStore from "src/store/post.store";
import PostService from "src/services/post.service";
import { AutocompleteModel } from "src/models/shared.model";
import { RouteComponentProps } from "react-router";

type Props = {
    states: State[],
    navigation: RouteComponentProps
}

const SearchInput = (props: Props) => {

    const { states } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedState, setSelectedState] = useState<State | "all">("all");
    const [selectedCity, setSelectedCity] = useState<CityModel | "all">("all");

    const [searchValue, setSearchValue] = useState('');
    const [suggestions, setSuggestions] = useState<AutocompleteModel[]>([]);

    useEffect(() => {

        // alert(states.length)
        if (states.length) {
            // setSelectedState(states[0]);
        }

    }, []);

    useEffect(() => {

        if (selectedState && selectedState != "all") {
            setSelectedCity(selectedState.cities[0]);
        }

    }, [selectedState]);

    const handleStateChange = (value: string) => {
        if (value == "all") {
            setSelectedState("all");
        }
        else {
            const state = states.find(e => e.id == value);
            if (state) setSelectedState(state);
        }
    }

    const handleCityChange = (value: string) => {
        if (value == "all") {
            setSelectedState("all");
        }
        else if (selectedState && selectedState != "all") {
            const city = selectedState.cities.find(e => e.id == value);
            if (city) setSelectedCity(city);
        }
    }

    // Use your imagination to render suggestions.
    const renderSuggestion = (suggestion: AutocompleteModel) => (
        <div>
            <div style={{ fontWeight: 'bold', fontSize: 13 }}>
                {suggestion.title}
            </div>
            <div style={{ color: 'gray', fontSize: 13 }}>
                {suggestion.subcategoryName}
            </div>
        </div>
    );

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    const getSuggestionValue = (suggestion: AutocompleteModel) => suggestion.title;

    const fetchAutocomplete = (e: any) => {
        PostService.autocomplete(e.value)
            .then((res) => {
                if (!res || !res.data) return;
                setSuggestions(res.data);
            })
    }

    const handleSelectAutocomplete = (data: Autosuggest.SuggestionSelectedEventData<AutocompleteModel>) => {
        props.navigation.history.push(`/posts/${data.suggestion.subcategoryId}?name=${data.suggestion.title}`)
    }

    return (
        <div
            style={{
                width: "100%",
                backgroundColor: 'inherit'
            }}
        >
            <Box
                style={{
                    padding: 10,
                    flexDirection: "row",
                    justifyContent: "center",
                    display: "flex",
                    //background: "#fff"
                }}
            >
                <div
                    style={{
                        width: 100,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        border: "1.5px solid #E2E8F0",
                        padding: 5,
                        borderRadius: 10,
                        height: 40
                    }}
                    onClick={onOpen}
                >
                    <span
                        className="ellipse"
                    >
                        {selectedCity && selectedCity != "all" ? selectedCity.name : " All "}
                    </span>
                    <ChevronDownIcon
                        size={20}
                    />
                </div>
                <InputGroup size="md">
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={fetchAutocomplete}
                        onSuggestionsClearRequested={() => {}}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={{
                            value: searchValue,
                            style: {
                                //border: '1px solid gray',
                                //width: 250,
                                //padding: 10,
                                //borderRadius: 10
                            },
                            placeholder: 'Search',
                            onChange: (_, value) => setSearchValue(value.newValue)
                        }}
                        onSuggestionSelected={(_, data) => {
                            handleSelectAutocomplete(data)
                        }}
                    />
                    <InputRightElement width="4.5rem">
                        <Box
                            background="blue.600"
                            style={{
                                padding: 5,
                                borderRadius: "100%",
                                display: "flex",
                                flexDirection: "row",
                                alignSelf: "center",
                                alignContent: "flex-end",
                                marginLeft: 20
                            }}
                        >
                            <FiSearch
                                size={20}
                                color="#fff"
                            />
                        </Box>
                    </InputRightElement>
                </InputGroup>

                <Modal
                    isOpen={isOpen}
                    onClose={onClose}
                    scrollBehavior="inside"
                >
                    <ModalOverlay />
                    <ModalContent style={{ minHeight: 250 }}>
                        <ModalHeader>Choose your location</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <h1>State</h1>
                            <Select
                                value={selectedState == "all" ? "all" : selectedState.id}
                                onChange={e => handleStateChange(e.target.value)}
                                style={styles.select}
                            >
                                <option key="all" value="all">
                                    All
                                </option>
                                {states.map(e => (
                                    <option
                                        key={e.id}
                                        value={e.id}
                                    >
                                        {e.name}
                                    </option>
                                ))}
                            </Select>

                            <h1 style={{ marginTop: 10 }}>City</h1>
                            {selectedState && (
                                <Select
                                    style={styles.select}
                                    onChange={e => handleCityChange(e.target.value)}
                                >
                                    <option
                                        key="all"
                                        value="all"
                                    >
                                        All
                                    </option>
                                    {selectedState != "all" && selectedState.cities.map(e => (
                                        <option
                                            key={e.id}
                                            value={e.id}
                                        >
                                            {e.name}
                                        </option>
                                    ))}
                                </Select>
                            )}

                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={onClose}>
                                Done
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </div>
    )

};
export default SearchInput;

const styles = createStylesheet({
    select: {
        marginTop: 10
    }
})