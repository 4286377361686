import { Box } from "@chakra-ui/react";
import { PostModel } from "src/models/post.model";
import { UserModel, UserProfileModel } from "src/models/user.model";
import { createStylesheet } from "src/utils/style";
import PostDescription from "./post.description";
import PostDetail from "./post.detail";
import PostRelated from "./post.related";
import PostSeller from "./post.seller";

type Props = {
    currentUser?: UserProfileModel,
    post: PostModel,
    related: PostModel[],
    onClickMessage: () => void;
    onClickWhatsapp: () => void;
    onClickUser: () => void;
    onDelete: (model: PostModel) => void;
    onUpdate: (model: PostModel) => void;
    onClickRelated: (post: PostModel) => void;
}

const PostDetailDesktop = (props: Props) => {

    const { post, related, onClickRelated } = props;

    return (
        <div style={styles.root}>
            <div className="row-left box">
                <div>
                    <Box
                        borderWidth={2}
                        style={styles.box}
                    >
                        <PostDetail post={post} />
                    </Box>

                    <Box
                        borderWidth={2}
                        style={{ marginTop: 10, padding: 10 }}
                    >
                        <PostDescription post={post} />
                    </Box>

                    <PostRelated related={related} onClick={onClickRelated} />
                </div>
            </div>

            <div className="box">
                <PostSeller
                    post={post}
                    currentUser={props.currentUser}
                    onUpdate={() => props.onUpdate(post)}
                    onClickUser={props.onClickUser}
                    onClickMessage={props.onClickMessage}
                    onDelete={() => props.onDelete(post)}
                    onClickWhatsapp={props.onClickWhatsapp}
                />
            </div>
        </div>
    )

};
export default PostDetailDesktop;
const styles = createStylesheet({
    root: {
        display: "flex"
    },
    box: {
        padding: 10,
    },
})

