import { Avatar, Box, Button, Flex, Grid, GridItem, Heading, HStack, Input, InputGroup, InputLeftElement, SimpleGrid, Spacer, Tab, Table, TabList, TabPanel, TabPanels, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { matchPath, RouteComponentProps, useLocation } from "react-router";
import PageNotFound from "src/components/page.notfound";
import { formatMoney } from "src/services/util";
import postStore from "src/store/post.store";
import { createStylesheet } from "src/utils/style";
import { Carousel } from 'react-responsive-carousel';
import moment from "moment";
import { AiOutlineMessage, AiOutlineSearch } from 'react-icons/ai';
import { FaPhoneAlt } from 'react-icons/fa';
import { IoMailSharp, IoLocation, IoCalendar } from 'react-icons/io5';
import { Tabs } from "@chakra-ui/react";
import postService from "src/services/post.service";
import Post from "src/store/post";
import HorizontalScroll from "src/components/horizontal.scroll";
import PostCard from "src/components/posts/post.card";
import { PostModel } from "src/models/post.model";
import { ImWhatsapp } from "react-icons/im"
import useWindowDimensions from "src/window.dimension";
import PostDetailDesktop from "src/components/posts/post.detail.desktop";
import PostDetailMobile from "src/components/posts/post.detail.mobile";
import authStore from "src/store/auth.store";

type Props = RouteComponentProps<any> & {}

const PostDetailPage = observer((props: Props) => {

    const { user } = authStore;

    const match = matchPath<{ id: string }>(props.history.location.pathname, {
        path: "/posts/:id/detail",
        exact: true,
        strict: true
    });
    const postId = match?.params.id;
    const [loading, setLoading] = useState(false);
    const [related, setRelated] = useState<PostModel[]>([]);

    const { width } = useWindowDimensions();
    const { pathname } = useLocation();

    if (!postId) return <PageNotFound {...props} />

    const post = postStore.getById(postId);

    useEffect(() => {

        console.log('load post:::')

        if (!post) {
            fetchProduct();
            return;
        }

    }, []);

    useEffect(() => {

        fetchProduct();

    }, [pathname]);

    const fetchProduct = () => {
        if (loading) return;
        setLoading(true);
        postStore.fetchById(postId)
            .then(() => fetchRelated())
            .finally(() => setLoading(false))
    }

    const fetchRelated = () => {
        postService.getRelated(postId)
            .then(res => {
                if (!res || !res.data) return;
                setRelated(res.data);
            })
            .finally(() => { })
    }

    const handleClickPost = () => {
        if (!post) return;
        props.history.push(`/posts/${post.id}/detail`)
    }

    const handleMessage = () => {

    }

    const handleWhatsapp = () => {
        if (!post) return;

        window.open(
            `https://api.whatsapp.com/send?phone=${post.user.phone}&text=Hi, I’m interested in ${post.title}.%0a%0afound at ${encodeURIComponent(window.location.href)},%0a%0aplease contact me.%0aThanks`,
            '_blank'
        )?.focus();
    }

    const handleClickUser = () => {
        props.history.push(`/users/${post?.user.id}`)
    }

    const handleUpdate = (post: PostModel) => {
        props.history.push(`/posts/${post.id}/update`)
    }

    const handleDelete = (post: PostModel) => {

    }

    return (
        <div>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    {post ? (
                        <div style={styles.root}>

                            {width > 900 ? (
                                <PostDetailDesktop
                                    post={post}
                                    currentUser={user}
                                    onClickUser={handleClickUser}
                                    related={related}
                                    onUpdate={handleUpdate}
                                    onDelete={handleDelete}
                                    onClickMessage={handleMessage}
                                    onClickWhatsapp={handleWhatsapp}
                                    onClickRelated={handleClickPost}
                                />
                            ) : (
                                <PostDetailMobile
                                    post={post}
                                    currentUser={user}
                                    onUpdate={handleUpdate}
                                    related={related}
                                    onClickUser={handleClickUser}
                                    onDelete={handleDelete}
                                    onClickMessage={handleMessage}
                                    onClickWhatsapp={handleWhatsapp}
                                    onClickRelated={handleClickPost}
                                />
                            )}

                        </div>
                    ) : (
                        <PageNotFound {...props} />
                    )}
                </>
            )}
        </div>
    )

});
export default PostDetailPage;

const styles = createStylesheet({
    root: {
        padding: 5
    },
    box: {
        padding: 10,
    },
    avatarRow: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
        //alignItems: 'center'
    },
    memberSince: {
        color: 'gray',
        fontSize: 14
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    contact: {
        fontSize: 13,
        marginLeft: 10
    }
})
