import { SubCategoryModel, CategoryModel, SubCategoryAttributeModel } from "../models/category.model";


export default class Category {

    readonly id: string;
    readonly name: string;
    readonly images: string[];
    readonly subCategories: SubCategoryModel[] = [];

    constructor(model: CategoryModel) {
        this.name = model.name;
        this.id = model.id;
        this.images = model.images;
        if (Array.isArray(model.subCategories)) {
            // sort subcategories attributes

            var subcategories: SubCategoryModel[] = [];
            for(const sub of model.subCategories) {
                var attrs: SubCategoryAttributeModel[] = []
                if (Array.isArray(sub.attributes)) {
                    attrs.push(...sub.attributes.filter(e => e.type == "CHOOSE"));
                    attrs.push(...sub.attributes.filter(e => e.type == "STRING"));
                    attrs.push(...sub.attributes.filter(e => e.type == "NUMBER"));
                    attrs.push(...sub.attributes.filter(e => e.type == "YES_OR_NO"));
                }
                sub.attributes = attrs;
                subcategories.push(sub);
            }

            this.subCategories = subcategories;
        }
    }
}
