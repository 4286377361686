import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

type Props = {
    images: { url: string }[]
}

const ChatMessageImages = (props: Props) => {

    const { images } = props;

    return (
        <div>
            {images.length > 0 && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                    }}
                >
                    {images.map((a, index) => (
                        <Zoom
                            key={a.url}
                            overlayBgColorStart='rgba(0, 0, 0, 0.2)'
                            overlayBgColorEnd='rgba(0, 0, 0, 0.5)'
                        >
                            <img
                                style={{
                                    objectFit: 'cover',
                                    width: 120,
                                    height: 120,
                                    borderRadius: 5,
                                    marginLeft: 10,
                                    marginTop: 5,
                                    border: '2px solid #f9f9f9'
                                }}
                                src={a.url}
                            />
                        </Zoom>
                    ))}
                </div>
            )}
        </div>
    );
};
export default ChatMessageImages;
