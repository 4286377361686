import { Box, Heading, SimpleGrid } from "@chakra-ui/react";
import { RouteComponentProps } from "react-router";
import { CategoryModel } from "src/models/category.model";


type Props = RouteComponentProps & {
    categories: CategoryModel[]
}

const CategoryTabs = (props: Props) => {

    const { categories } = props;

    return (
        <div style={{ padding: 40 }}>
            <Heading as="h4" size="lg">
                All categories
            </Heading>
            <div style={{ padding: 10 }}>
                <SimpleGrid minChildWidth="200px" spacing="40px">
                    {categories.map(e => (
                        <Box key={e.id}>
                            <Heading as="h2" size="md">
                                {e.name}
                            </Heading>
                            <div style={{ marginTop: 5 }}>
                                {e.subCategories.map(s => (
                                    <div key={s.id}>
                                        <a href='#'
                                           onClick={() => props.history.push(`/posts/${s.id}`)}
                                        >
                                            {s.name}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </Box>
                    ))}
                </SimpleGrid>
            </div>
        </div>
    )

};
export default CategoryTabs;
