import { createStylesheet } from "src/utils/style";
import { Heading, Text } from '@chakra-ui/react'
import Zoom from 'react-medium-image-zoom';
import { GrClose } from 'react-icons/gr';
import 'react-medium-image-zoom/dist/styles.css'



type Props = {
    attachments: File[],
    onRemoveAttachments: (e: File) => void;
    onSelectImage: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PostFormImageSection = (props: Props) => {

    const { attachments } = props;

    return (
        <div>
            <input id="myInput"
                type="file"
                //ref={fileInputRef}
                //style={{ display: 'none' }}
                accept="image/png, image/gif, image/jpeg, image/webp"
                onChange={props.onSelectImage}
            />

            {attachments.length > 0 && (
                <div
                // style={styles.attachments}
                >
                    {attachments.map((e, index) => (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: 10
                                }}
                            >
                                <Zoom
                                    overlayBgColorStart='rgba(0, 0, 0, 0.2)'
                                    overlayBgColorEnd='rgba(0, 0, 0, 0.5)'
                                >
                                    <img
                                        src={URL.createObjectURL(e)}
                                        style={{
                                            width: 80,
                                            height: 80,
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Zoom>

                                <GrClose
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => props.onRemoveAttachments(e)}
                                />
                            </div>
                            {index != attachments.length - 1 && (
                                <hr />
                            )}
                        </>
                    ))}
                </div>
            )}
        </div>
    )

};
export default PostFormImageSection;
const styles = createStylesheet({

});
