import { Tag, TagLabel } from "@chakra-ui/react";
import { PostAttributeSearchModel } from "src/models/post.model";
import { AiOutlineClose } from 'react-icons/ai';


type Props = {
    filter: PostAttributeSearchModel[],
    onRemove?: (e: PostAttributeSearchModel) => void;
}

const PostSubcategoryFilterList = (props: Props) => {

    const { filter, onRemove } = props;

    const handleRemove = (e: PostAttributeSearchModel) => {
        if (onRemove) onRemove(e);
    }

    const renderValue = (e: PostAttributeSearchModel) => {
        if (e.type === "YES_OR_NO") {
            return e.value ? 'Yes' : 'No';
        }
        else if (e.type === "CHOOSE") {
            return e.value;
        }
        else {
            if (e.minValue && e.maxValue) {
                return e.minValue == e.maxValue ? e.minValue : `From ${e.minValue} to ${e.maxValue}`
            }
            else if (e.minValue && !e.maxValue) {
                return `Atleast ${e.minValue}`
            }
            else {
                return `Up to ${e.maxValue}`
            }
        }
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            }}
        >
            {filter.map(e => (
                <div key={e.subcategoryAttributeId} style={{ marginLeft: 5 }}>
                    <Tag size="md" variant="subtle" colorScheme="pink" style={{ padding: 10 }}>
                        <TagLabel>
                            {e.attributeName}: {renderValue(e)}
                        </TagLabel>

                        <a href="#" onClick={() => handleRemove(e)}>
                            <AiOutlineClose style={{ marginLeft: 5 }} />
                        </a>
                    </Tag>
                </div>
            ))}
        </div>
    )

};
export default PostSubcategoryFilterList;
