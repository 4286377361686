import { Button, Heading } from "@chakra-ui/react"
import { RouteComponentProps } from "react-router";
import { createStylesheet } from "src/utils/style";

interface Props extends RouteComponentProps<any> {

}

const PageNotFound = (props: Props) => {

    return (
        <div
            style={{
                padding: 10,
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center"
            }}
        >
            <Heading size="lg" style={styles.label}>
                Sorry, this page isn't available.
            </Heading>
            <Heading size="sm" style={{ textAlign: "center", marginTop: 10 }}>
                We can cannot find the page your are looking for, perhaps it has been removed.
            </Heading>
            <div
               style={{
                   display: "flex",
                   justifyContent: "center",
                   marginTop: 15
               }}
            >
                <Button
                    onClick={() => props.history.push("/")}
                    colorScheme="blue"
                >
                    Go to home page
                </Button>
            </div>
        </div>
    )

};
export default PageNotFound;

const styles = createStylesheet({
    label: {
        textAlign: "center"
    }
});
