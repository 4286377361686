import { Box } from "@chakra-ui/react";
import { PostModel } from "src/models/post.model";
import HorizontalScroll from "../horizontal.scroll";
import PostCard from "./post.card";

type Props = {
    related: PostModel[],
    onClick: (product: PostModel) => void;
}

const PostRelated = (props: Props) => {

    const { related, onClick } = props;

    return (
        <div>
            {related.length > 0 && (
                <Box
                    borderWidth={2}
                    style={{
                        marginTop: 10,
                        padding: 10
                    }}
                >

                    <HorizontalScroll
                    //maxWidth={900}
                    >
                        {related.map(e => (
                            <div key={e.id}>
                                <PostCard
                                    post={e}
                                    onClick={e => onClick(e)}
                                />
                            </div>
                        ))}
                    </HorizontalScroll>
                </Box>
            )}
        </div>
    )

};
export default PostRelated;
