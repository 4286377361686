import { ChatModel } from "src/models/chat.model";
import { createStylesheet } from "src/utils/style";
import { Text, Heading, Avatar, Image } from "@chakra-ui/react";
import { IoMdMore } from 'react-icons/io';
import moment from "moment";

type Props = {
    chat: ChatModel,
    selected: boolean
}


const ChatListCard = (props: Props) => {

    const { chat, selected } = props;

    return (
        <div
            style={{...styles.root, background: selected ? '#EAF3FF' : '#fff'}}
        >
            <div style={styles.left}>
                <div>
                    <Avatar
                        // boxSize='50px'
                        //objectFit='cover'
                        src={chat.post.images ? chat.post.images[0].url : ''}
                        alt=""
                    />
                </div>

                <div
                    style={{
                        marginLeft: 5
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div>
                            <Text
                                size="sm"
                                style={{
                                    textTransform: 'capitalize',
                                    marginLeft: 5,
                                    fontWeight: 600
                                }}
                                className="ellipse one-line"
                            >
                                {chat.post.user.firstName} {chat.post.user.lastName}
                            </Text>
                            <Text
                                className="ellipse two-lines"
                                style={{
                                    marginLeft: 5,
                                    fontSize: 14
                                }}
                            >
                                {chat.post.title}
                            </Text>
                        </div>
                    </div>

                    <Text
                        style={styles.message}
                        className="ellipse two-lines"

                    >
                        {chat.lastMessage?.message}
                    </Text>

                </div>
            </div>

            <div style={styles.right}>
                <Text style={styles.dateSent}>
                    {moment(chat.lastMessage?.dateSent).calendar()}
                </Text>
                <IoMdMore
                    color='gray'
                    size={22}
                    style={{
                        marginTop: 10
                    }}
                />
            </div>
        </div>
    )

};
export default ChatListCard;
const styles = createStylesheet({
    root: {
        padding: 10,
        //marginTop: 20,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    left: {
        display: 'flex',
        flexDirection: 'row'
    },
    message: {
        marginTop: 7,
        color: 'gray',
        fontSize: 14,
        marginLeft: 5
    },
    dateSent: {
        fontSize: 12,
        color: 'gray'
    },
    right: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    }
});
