import { Stack, Skeleton, Button } from "@chakra-ui/react";
import { PostModel } from "src/models/post.model";
import { createStylesheet } from "src/utils/style";
import useWindowDimensions from "src/window.dimension";
import PostCard from "./post.card";
import PostCardVertical from "./post.card.vertical";

type Props = {
    posts: PostModel[],
    hasNext: boolean
    loading: boolean,
    loadingMore: boolean,
    onClick: (post: PostModel) => void;
    onLoadMore: () => void;
}


const PostList = (props: Props) => {

    const { posts, hasNext, loadingMore, loading, onClick, onLoadMore } = props;

    const { width } = useWindowDimensions();

    return (
        <div>
            <div
                style={{
                    ...styles.content,
                    justifyContent: width > 700 ? 'center' : "start",
                    flexDirection: width > 700 ? 'row' : 'column'
                }}
            >
                {loading ? (
                    [...Array(10).keys()].map(_ => (
                        <Stack>
                            <Skeleton height="20px" />
                            <Skeleton height="20px" />
                            <Skeleton height="20px" />
                        </Stack>
                    ))
                ) : (
                    <>
                        {posts.map(e => (
                            width > 700 ? (
                                <PostCard
                                    key={e.id}
                                    post={e}
                                    onClick={onClick}
                                />
                            ) : (
                                <PostCardVertical
                                    key={e.id}
                                    post={e}
                                    onClick={onClick}
                                />
                            )
                        ))}
                    </>
                )}
            </div>

            {hasNext && !loading && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                    }}
                >
                    <Button
                        colorScheme="blue"
                        disabled={loadingMore}
                        isLoading={loadingMore}
                        onClick={onLoadMore}
                    >
                        Load more
                    </Button>
                </div>
            )}
        </div>
    )
};
export default PostList;
const styles = createStylesheet({
    content: {
        padding: 10,
        display: "flex",
        flexWrap: "wrap"
    }
});
