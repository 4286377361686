import { action, observable } from "mobx";
import CategoryService from "../services/category.service";
import Category from "./category";


class CategoryStore  {

    @observable private _categories: Category[] = [];

    get all() {
        return this._categories;
    }

    get getById() {
        const ctx = this;
        return (id: string) => {
            return ctx._categories.find(e => e.id == id);
        }
    }

    @action async loadCategories() {
        const res = await CategoryService.getAll();
        const data = res.data;
        if (Array.isArray(data)) {
            this._categories = data.map(e => new Category(e));
        }
    }
}
export default new CategoryStore();
