import { useEffect, useRef, useState } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import './App.css';
import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    useColorModeValue,
    useDisclosure} from '@chakra-ui/react';
import {
    FiMenu as HamburgerIcon,
    FiX as CloseIcon} from 'react-icons/fi';
import LargeWithAppLinksAndSocial from './components/footer';
import HomePage from './pages/home.page';
import { observer } from 'mobx-react';
import authStore from './store/auth.store';
import stateStore from './store/state.store';
import categoryStore from './store/category.store';
import SignupPage from './pages/signup.page';
import CategoryPage from './pages/category.page';
import CategoryTabs from './components/categories/category.tabs';
import SearchInput from './components/search.input';
import PostPage from './pages/posts/post.page';
import uiStore from './store/ui.store';
import ChatPage from './pages/chats/chats.page';
import UserProfilePage from './pages/users/user.profile.page';
import AuthPage from './pages/auth.page';
import AppDrawer, { AppDrawerAction } from './components/app.drawer';
import MyAdsPage from './pages/myads.page';
import PostFormPage from './pages/posts/post.form.page';
import PostDetailPage from './pages/posts/post.detail.page';
import PostFormUpdatePage from './pages/posts/post.form.update.page';
import ProfilePage from './pages/profile.page';
import AppLogo from './components/app.logo';
import AboutUsPage from './pages/about.page';


interface Props extends RouteComponentProps<any> {

}

const App = observer((props: Props) => {

    const [loading, setLoading] = useState(true);
    //const { isOpen, onToggle } = useDisclosure();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { all: states } = stateStore;
    const { all: categories } = categoryStore;

    const { showFooter, headerHeight, hideHeader, headerSticky } = uiStore;

    const { user } = authStore;

    const headerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {

        authStore.fetchProfile();
        loadData();

    }, []);

    useEffect(() => {

        const height = headerRef?.current?.clientHeight;
        if (height && headerHeight !== height) {
            uiStore.setHeaderHeight(height);
        }

    });

    const loadData = async () => {
        //console.log("load data....")
        //await appStore.initialize();
        await stateStore.fetch();
        categoryStore.loadCategories()
            .finally(() => setLoading(false));
    }

    const handleDrawerAction = (e: AppDrawerAction) => {
        if (e === 'my_ads') {
            props.history.push('/myads')
        }
        if (e === 'post_ad') {
            props.history.push('/posts/new')
        }
        if (e === 'profile') {
            props.history.push('/profile');
        }
        onClose();
    }

    return (
        <>
            {!hideHeader && (
                <Box
                    style={{
                        top: 0,
                        position: headerSticky ? "sticky" : 'relative',
                        zIndex: 1000
                    }}
                    //borderBottomWidth={2}
                    id="app-header"
                    boxShadow="sm"
                    ref={headerRef}
                >
                    <Flex
                        bg={useColorModeValue('white', 'gray.800')}
                        color={useColorModeValue('gray.600', 'white')}
                        minH={'60px'}
                        py={{ base: 2 }}
                        px={{ base: 4 }}
                        borderBottom={1}
                        align={'center'}>
                        <Flex
                        //flex={{ base: 1, md: 'auto' }}
                        //ml={{ base: -2 }}
                        //display={{ base: 'flex', md: 'none' }}
                        >
                            {user && (
                                <IconButton
                                    onClick={onOpen}
                                    icon={
                                        isOpen ? <CloseIcon /> : <HamburgerIcon />
                                    }
                                    variant={'ghost'}
                                    aria-label={'Toggle Navigation'}
                                />
                            )}
                            <div
                                onClick={() => props.history.push('/')}
                                style={{
                                    cursor: 'pointer'
                                }}
                            >
                                <AppLogo size='small' />
                            </div>
                        </Flex>
                        <Flex
                            flex={{ base: 1 }}
                            display={{
                                base: 'none',
                                md: 'flex'
                            }}
                            justify={{
                                base: 'center',
                                md: 'center'
                            }}
                        >
                            <div
                                style={{ minWidth: 420 }}
                            >
                                <SearchInput
                                    states={states}
                                    navigation={props}
                                />
                            </div>
                        </Flex>

                        {!user && (
                            <Stack
                                flex={{ base: 1, md: 0 }}
                                justify={'flex-end'}
                                direction={'row'}
                                spacing={6}>
                                <Button
                                    as={'a'}
                                    fontSize={'sm'}
                                    fontWeight={400}
                                    variant={'link'}
                                    href={'#'}
                                    onClick={() => props.history.push('/auth')}
                                >
                                    Sign In
                                </Button>
                                <Button
                                    display={{ base: 'none', md: 'inline-flex' }}
                                    fontSize={'sm'}
                                    fontWeight={600}
                                    color={'white'}
                                    bg={'pink.400'}
                                    href={'#'}
                                    _hover={{
                                        bg: 'pink.300',
                                    }}
                                    onClick={() => props.history.push('/signup')}
                                >
                                    Sign Up
                                </Button>
                            </Stack>

                        )}

                    </Flex>
                    <Flex
                        //flex={{ base: 1, md: 'auto' }}
                        //ml={{ base: -2 }}
                        display={{ base: 'flex', md: 'none' }}
                        bg={useColorModeValue('white', 'gray.800')}
                        color={useColorModeValue('gray.600', 'white')}
                    >
                        <SearchInput states={states} navigation={props} />
                    </Flex>
                </Box>
            )}

            <AppDrawer
                isOpened={isOpen}
                onClose={onClose}
                user={user}
                onAction={handleDrawerAction}
            />

            {loading ? (
                <div>
                    Loading...
                </div>
            ) : (
                <Switch>
                    <Route path="/signup" component={SignupPage} />
                    <Route path="/auth" component={AuthPage} />
                    <Route path="/categories/:id" component={CategoryPage} />
                    <Route path="/posts/:id/update" component={PostFormUpdatePage} />
                    <Route path="/posts/:id/detail" component={PostDetailPage} />
                    <Route path="/posts/new" component={PostFormPage} />
                    <Route path="/posts/:subcategoryId" component={PostPage} />
                    <Route path="/chats" component={ChatPage} />
                    <Route path="/myads" component={MyAdsPage} />
                    <Route path="/profile" component={ProfilePage} />
                    <Route path="/users/:id" component={UserProfilePage} />
                    <Route path="/about_us" component={AboutUsPage} />
                    <Route path="/" component={HomePage} />
                </Switch>
            )}
            {showFooter && (
                <>
                    <CategoryTabs categories={categories} {...props} />
                    <LargeWithAppLinksAndSocial />
                </>
            )}
        </>
    );

});
export default App;
