import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Route, Switch } from 'react-router';
import { BrowserRouter as Router, BrowserRouter } from 'react-router-dom';
import 'react-medium-image-zoom/dist/styles.css'
import scrollToTop from './components/scroll.to.top';
import ScrollToTop from './components/scroll.to.top';


const theme = extendTheme({
    config: {
        cssVarPrefix: "ck",
        //initialColorMode: "dark"
    }
})


ReactDOM.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <BrowserRouter>
                <Router>
                    <>
                        <ScrollToTop />
                        <Switch>
                            <Route path="/" component={App} />
                        </Switch>
                    </>
                </Router>
            </BrowserRouter>
        </ChakraProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
