import State from "./state";
import { observable, action } from "mobx";
import StateService from "../services/state.service";


class StateStore {

    @observable private _states: State[] = [];

    get all() { 
        return this._states;
    }

    @action async fetch() {
        const response = await StateService.fetch();
        const data = response.data;
        if (Array.isArray(data)) {
            this._states = data.map(e => new State(e));
        }
    }
}

export default new StateStore();

