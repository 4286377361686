import { Button } from "@chakra-ui/button";
import { Box } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/react";
import { useState } from "react";
import { UserProfileModel } from "src/models/user.model";
import { toBase64 } from "src/utils/util";
import ErrorModal from "../error.modal";

type Props = {
    loading: boolean,
    user: UserProfileModel,
    onUploadProfilePic: (base64: string) => void;
}

const ProfileImageUpload = (props: Props) => {

    const { user, loading } = props;
    const [file, setFile] = useState<File>();
    const [error, setError] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !e.target.files.length) return;
        const file = e.target.files[0];
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg', 'image/webp'];
        if (!validImageTypes.includes(file['type'])) {
            // invalid file type code goes here.
            setError('We currenly only accept images (jpeg, gif, webp and png)')
            return;
        }
        var filesize = Number(((file.size / 1024) / 1024).toFixed(4)); // MB
        if (filesize > 5) {
            setError('The file cannot be larger than 5 MB')
            return;
        }
        setFile(file);
    }

    const handleUpload = async () => {
        if (!file) return;
        const base64 = await toBase64(file);
        props.onUploadProfilePic(base64 + '');
    }

    return (
        <div>
            <ErrorModal
                message={error}
                onClose={() => setError('')}
            />
            <Box
                borderWidth={1}
                style={{
                    padding: 10,
                    maxWidth: 500,
                    marginTop: 15,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <Heading size='md'>
                    Change your profile picture
                </Heading>
                <img
                    src={file ? URL.createObjectURL(file) : user.profile}
                    style={{
                        width: 180,
                        height: 180,
                        objectFit: 'contain'
                    }}
                />
                <input id="myInput"
                    type="file"
                    //ref={fileInputRef}
                    //style={{ display: 'none' }}
                    accept="image/png, image/gif, image/jpeg, image/webp"
                    onChange={handleChange}
                />
                <Button
                    colorScheme="blue"
                    disabled={loading || !file}
                    isLoading={loading}
                    onClick={handleUpload}
                >
                    Upload
                </Button>
            </Box>
        </div>
    )
};
export default ProfileImageUpload;