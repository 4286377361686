import { observer } from "mobx-react";
import { matchPath, RouteComponentProps, useLocation } from "react-router";
import { createStylesheet } from "src/utils/style";
import { useEffect, useRef, useState } from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Skeleton, Stack, Tag, TagLabel } from "@chakra-ui/react";
import { GoSettings } from 'react-icons/go';
import categoryStore from "src/store/category.store";
import HorizontalScroll from "src/components/horizontal.scroll";
import postStore from "src/store/post.store";
import uiStore from "src/store/ui.store";
import { PostAttributeSearchModel, PostSearchModel } from "src/models/post.model";
import PostCard from "src/components/posts/post.card";
import useWindowDimensions from "src/window.dimension";
import PostCardVertical from "src/components/posts/post.card.vertical";
import PostSearchModal, { PostSearchModalRef } from "src/components/posts/post.search.modal";
import { SubCategoryAttributeModel } from "src/models/category.model";
import PostSubcategoryFilterList from "src/components/posts/post.subcategory.filter";
import subcategoryStore from "src/store/subcategory.store";
import PostPriceFilterTag from "src/components/posts/post.price.filter.tag";
import Post from "src/store/post";
import authStore from "src/store/auth.store";
import PostList from "src/components/posts/post.list";
import queryString from "query-string";


interface Props extends RouteComponentProps<any> {

}

const PostPage = observer((props: Props) => {

    const { all: posts, hasNext } = postStore;
    const [loading, setLoading] = useState(false);
    const [filter, _] = useState<PostSearchModel>({ limit: 25 });
    const [subcategoryFilter, setSubcategoryFiler] = useState<PostAttributeSearchModel[]>([]);
    const [minPrice, setMinPrice] = useState<number>();
    const [maxPrice, setMaxPrice] = useState<number>();
    const [isLoadingMore, setLoadingMore] = useState(false);
    const [searchName, setSearchName] = useState('');

    
    const { height, width } = useWindowDimensions();
    const filterModalRef = useRef<PostSearchModalRef>(null);

    const match = matchPath<{ subcategoryId: string }>(props.history.location.pathname, {
        path: "/posts/:subcategoryId",
        exact: true,
        strict: true
    });
    const subcategoryId = match?.params.subcategoryId;

    useEffect(() => {

        fetchPosts();

        props.history.listen((location) => {
            const parse = queryString.parse(location.search);
           // console.log(parse.name);
            if (parse) {
                const name = parse.name as string;
                setSearchName(name)
            }
        })

    }, []);

    useEffect(() => {

        fetchPosts();

    }, [subcategoryFilter, minPrice, maxPrice, searchName]);

    const subcategory = () => {
        return subcategoryId ? categoryStore.all.flatMap(e => e.subCategories).find(e => e.id == subcategoryId) : null;
    }

    const category = () => {
        if (!subcategoryId) return;
        const cat = categoryStore.all.find(e => e.subCategories.find(s => s.id == subcategoryId));
        return cat;
    }

    const handleGoTo = (route: string) => {
        props.history.push(`/${route}`)
    }

    const fetchPosts = () => {
        console.log('fetch.....')
        const { selectedCityId, selectedStateId } = uiStore;
        setLoading(true);
        const searchModel: PostSearchModel = {
            name: searchName,
            cityId: selectedCityId,
            stateId: selectedStateId,
            subcategoryId: subcategoryId,
            attributes: subcategoryFilter,
            includeUserOwnAds: false,
            limit: 15
        }
        if (minPrice) {
            searchModel.fromPrice = minPrice;
        }
        if (maxPrice) {
            searchModel.toPrice = maxPrice;
        }
        postStore.search(searchModel)
            .finally(() => setLoading(false))
    }

    const handleOnClose = (e: PostAttributeSearchModel[]) => {
        setSubcategoryFiler(e);
        //console.log("on close", JSON.stringify(e, null, 2))
    }

    const handleClearPriceFilter = () => {
        setMinPrice(undefined);
        setMaxPrice(undefined);
    }

    const handleClearAttributeFilter = () => {
        setSubcategoryFiler([]);
        setMinPrice(undefined);
        setMaxPrice(undefined);
    }

    const handleRemoveAttributeFilter = (filter: PostAttributeSearchModel) => {
        setSubcategoryFiler(subcategoryFilter.filter(e => e.subcategoryAttributeId != filter.subcategoryAttributeId));
    }

    const handleViewDetail = (e: Post) => {
        props.history.push(`/posts/${e.id}/detail`, {})
    }

    const handleLoadMore = () => {
        if (isLoadingMore) return;
        setLoadingMore(true);
        postStore.next()
            .finally(() => setLoadingMore(false))
    }

    return (
        <div>

            {subcategory() && (
                <Stack direction="row" spacing={4} style={{ marginLeft: 5 }}>
                    {subcategory()?.name}
                    <PostSearchModal
                        ref={filterModalRef}
                        selectedSubcategory={subcategory()!}
                        filter={filter}
                        values={subcategoryFilter}
                        minPrice={minPrice}
                        maxPrice={maxPrice}
                        onSetMinPrice={setMinPrice}
                        onSetMaxPrice={setMaxPrice}
                        onClose={handleOnClose}
                        onClear={handleClearAttributeFilter}
                    />
                </Stack>
            )}
            <HorizontalScroll
                leftHeaderNode={(
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <BreadcrumbLink
                                href="#"
                                onClick={() => handleGoTo("")}
                            >
                                Home
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        {category() && (
                            <BreadcrumbItem>
                                <BreadcrumbLink
                                    href="#"
                                    onClick={() => handleGoTo(`categories/${category()?.id}`)}
                                >
                                    {category()?.name}
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        )}
                        {subcategory() && (
                            <BreadcrumbItem>
                                <BreadcrumbLink href="#">
                                    {subcategory()?.name}
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        )}
                    </Breadcrumb>
                )}
                leftNode={(
                    <Stack direction="row" spacing={4} style={{ padding: 5 }}>
                        <Button
                            leftIcon={<GoSettings size={22} />}
                            variant="solid"
                            onClick={() => filterModalRef.current?.open()}
                        >
                            Filter
                        </Button>
                    </Stack>
                )}
            >
                {subcategory() && (
                    <Stack direction="row" spacing={4} style={{ marginLeft: 5 }}>
                        <Tag size="md" variant="subtle" colorScheme="pink" style={{ padding: 10 }}>
                            <TagLabel>
                                {subcategory()?.name}
                            </TagLabel>
                        </Tag>
                    </Stack>
                )}
                {minPrice != undefined || maxPrice != undefined ? (
                    <PostPriceFilterTag
                        minPrice={minPrice}
                        maxPrice={maxPrice}
                        onClear={handleClearPriceFilter}
                    />
                ) : ''}
                <PostSubcategoryFilterList
                    filter={subcategoryFilter}
                    onRemove={handleRemoveAttributeFilter}
                />
            </HorizontalScroll>

            <PostList
                posts={posts}
                loading={loading}
                loadingMore={isLoadingMore}
                hasNext={hasNext}
                onClick={handleViewDetail}
                onLoadMore={handleLoadMore}
            />
        </div>
    )
});
export default PostPage;
const styles = createStylesheet({
    content: {
        padding: 10,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    }
});
