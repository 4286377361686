import { createStylesheet } from "src/utils/style";
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { Heading } from "@chakra-ui/react";

type Props = {
    title: string,
    onGoBack: () => void;
}

const MobileHeader = (props: Props) => {

    const { title, onGoBack } = props;

    return (
        <div style={styles.root}>
            <AiOutlineArrowLeft
                onClick={onGoBack}
                size={22}
                fontWeight='bold'
            />
            <Heading
                style={{
                    marginLeft: 10
                }}
                size='lg'
            >
                {title}
            </Heading>
        </div>
    )

};
export default MobileHeader;
const styles = createStylesheet({
    root: {
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        background: '#EBEEEF'
    }
});
