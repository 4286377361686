import querystring from "query-string";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";


export interface PostQueryParams {
    body?: any;
    queryParams?: any;
}


class ApiService {
    
    private API_URLS = process.env.NODE_ENV === "development" ? 'http://localhost:5000/api' : 'https://api.sangano.co.zw/api';

    private requestConfig(){
        const config: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };
        return config;
    }

    public setServerURL(url: string) {
        this.API_URLS = url;
    }

    /**
     * Make a get request
     * @param url the url
     * @param queryParams
     */
    public get<T>(url: string, queryParams?: any): Promise<AxiosResponse<T>> {
        url = queryParams ? `${url}?${querystring.stringify(queryParams)}` : url;
        return axios.get(`${this.API_URLS}/${url}`, this.requestConfig());
    }

    /**
     * Make a post request
     * @param url the url
     * @param options
     */
    public post<T>(url: string, options: PostQueryParams): Promise<AxiosResponse<T>> {
        /*if (options) {
            return this.request(url, "post", options.queryParams, options.body);
        }*/
        if (options && options.queryParams) {
            url = `${url}?${querystring.stringify(options.queryParams)}`
        }
        return axios.post(`${this.API_URLS}/${url}`, options.body, this.requestConfig());
    }

    /**
     * Make a put request
     * @param url the url
     * @param data the params data (body)
     */
    public put<T>(url: string, data: any): Promise<AxiosResponse<T>> {
        // return this.request(url, "put", undefined, data);
        return axios.put(`${this.API_URLS}/${url}`, data, this.requestConfig());
    }

    /**
     * Make a delete request
     * @param url the url
     * @param queryParams
     */
    public delete<T>(url: string, queryParams?: any): Promise<AxiosResponse<T>> {
        /* return this.request(url, "delete", queryParams);*/
        url = queryParams ? `${url}?${querystring.stringify(queryParams)}` : url;
        return axios.delete(`${this.API_URLS}/${url}`, this.requestConfig());
    }

    /**
     * Set the authentication token
     * @param token authentication token
     */
    public setAuthenticationToken(token: string) {
        throw Error("Not yet implemented");
    }
}

const apiService = new ApiService();
export default apiService;
