import { Box } from "@chakra-ui/react";
import { PostModel } from "src/models/post.model";
import { UserModel, UserProfileModel } from "src/models/user.model";
import { createStylesheet } from "src/utils/style";
import PostDescription from "./post.description";
import PostDetail from "./post.detail";
import PostRelated from "./post.related";
import PostSeller from "./post.seller";

type Props = {
    post: PostModel,
    currentUser?: UserProfileModel,
    related: PostModel[],
    onClickMessage: () => void;
    onClickWhatsapp: () => void;
    onClickUser: () => void;
    onUpdate: (post: PostModel) => void;
    onDelete: (post: PostModel) => void;
    onClickRelated: (post: PostModel) => void;
}


const PostDetailMobile = (props: Props) => {

    const { post, related, onClickRelated } = props;

    return (
        <div>
            <Box
                borderWidth={2}
                style={styles.box}
            >
                <PostDetail post={post} />
            </Box>

            <Box
                borderWidth={2}
                style={{ marginTop: 10, padding: 10 }}
            >
                <PostDescription post={post} />
            </Box>

            <div style={{ marginTop: 10 }}>
                <PostSeller
                    post={post}
                    currentUser={props.currentUser}
                    onDelete={() => props.onDelete(post)}
                    onUpdate={() => props.onUpdate(post)}
                    onClickUser={props.onClickUser}
                    onClickMessage={props.onClickMessage}
                    onClickWhatsapp={props.onClickWhatsapp}
                />
            </div>

            <PostRelated related={related} onClick={onClickRelated} />
        </div>
    )

};
export default PostDetailMobile;

const styles = createStylesheet({
    box: {
        padding: 10,
    }
})
