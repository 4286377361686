import apiService from "./api.service";
import { UserModel, UserProfileModel, UserUpdateModel, UpdatePasswordModel, SignupModel } from "../models/user.model";
import { ImageModel } from "../models/shared.model";


export default class AuthService {

    static auth(username: string, password: string) {
        return apiService.post<UserProfileModel>("signin", {
            body: {
                username,
                password
            }
        });
    }

    static signUp(model: SignupModel) {
        return apiService.post<UserProfileModel>("signup", {
            body: model
        })
    }

    static loadProfile() {
        return apiService.get<UserProfileModel>("session");
    }

    static countUnreadMessage() {
        return apiService.get<number>("unread_messages");
    }

    static update(model: UserUpdateModel) {
        return apiService.put<UserProfileModel>("account", model);
    }

    static removeProfile() {
        return apiService.delete("profile_picture");
    }

    static setProfilePic(data: string) {
        return apiService.put<ImageModel>("profile_picture", {
            data
        });
    }

    static updatePassword(model: UpdatePasswordModel) {
        return apiService.put("change_password", model);
    }

    static logout() {
        return apiService.post("logout", {});
    }
};
