import { Text, InputProps, Input, TextareaProps, Textarea } from "@chakra-ui/react"

interface Props extends TextareaProps {
    error?: string,
    bg?: string,
    onValueChanged: (value: string) => void;
}

const FormTextArea = (props: Props) => {

    const { error, bg = 'gray.100' } = props;
    
    return (
        <>
            <Textarea
                {...props}
                onChange={e => props.onValueChanged(e.target.value)}
                bg={bg}
                color={'black'}
                _placeholder={{
                    color: 'gray.500',
                }}
                focusBorderColor={error != undefined ? 'red' : 'blue.500'}
                isInvalid={error != undefined}
            />
            {error && (
                <Text
                    style={{
                        color: 'red'
                    }}
                >
                    {error}
                </Text>
            )}
        </>
    );

};
export default FormTextArea;
