import { observer } from "mobx-react";
import { RouterProps } from "react-router";
import CategoryCarousel from "src/components/categories/category.carousel";
import CategoryTabs from "src/components/categories/category.tabs";
import SignupHero from "src/components/signup.hero";
import authStore from "src/store/auth.store";
import Category from "src/store/category";
import categoryStore from "src/store/category.store";


interface Props extends RouterProps {

}

const HomePage = observer((props: Props) => {

    const { all: categories } = categoryStore;
    const { user } = authStore;

    const handleClickCategory = (category: Category) => {
        props.history.push(`/categories/${category.id}`)
    }

    return (
        <div>
            <CategoryCarousel
                categories={categories}
                onClick={handleClickCategory}
            />
            {!user && (
                <SignupHero router={props} />
            )}
        </div>
    )

});
export default HomePage;
