import { Heading, Text } from "@chakra-ui/react";
import { IoCalendar, IoLocation } from "react-icons/io5";
import { PostModel } from "src/models/post.model";
import { formatMoney } from "src/services/util";
import { Carousel } from 'react-responsive-carousel';
import moment from "moment";
import { createStylesheet } from "src/utils/style";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

type Props = {
    post: PostModel
}


const PostDetail = (props: Props) => {

    const { post } = props;

    return (
        <div>
            <Heading size='lg'>
                ${formatMoney(post.price)}
            </Heading>
            <Text fontSize='large'>
                {post.title}
            </Text>

            <div style={{ marginTop: 10 }}>
                {post.images && (
                    <Carousel
                       showArrows={true} 
                    >
                        {post.images.map(e => (
                            <div key={e.id}>
                                <Zoom
                                    overlayBgColorStart='rgba(0, 0, 0, 0.2)'
                                    overlayBgColorEnd='rgba(0, 0, 0, 0.5)'
                                >
                                    <img
                                        src={e.url}
                                        style={{
                                            width: 500,
                                            maxHeight: 350,
                                            objectFit: 'contain'
                                        }}
                                    />
                                </Zoom>
                            </div>
                        ))}
                    </Carousel>
                )}
            </div>

            <div style={{ ...styles.row, marginTop: 10, flexWrap: 'wrap' }}>
                <div style={styles.row}>
                    <IoLocation />
                    <Text style={{ marginLeft: 5 }}>
                        {post.stateName}, {post.cityName}
                    </Text>
                </div>

                <div style={{ ...styles.row, marginLeft: 10 }}>
                    <IoCalendar />
                    <Text style={{ marginLeft: 5 }}>
                        {moment(post.dateCreated).fromNow()}
                    </Text>
                </div>

            </div>
        </div>
    )

};
export default PostDetail;

const styles = createStylesheet({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
});
