import { Select } from "@chakra-ui/react";
import { SubCategoryAttributeModel } from "src/models/category.model";
import { PostAttributeSearchModel } from "src/models/post.model";
import {ChangeSubcategoryFilterModel} from "../posts/post.search.modal";

type Props = {
    attribute: SubCategoryAttributeModel,
    customValues?: string[],
    hasAny?: boolean,
    values: PostAttributeSearchModel[],
    onChange: (value: ChangeSubcategoryFilterModel) => void;
}
const SubcategoryAttributeList = (props: Props) => {

    const { attribute, customValues, hasAny, onChange, values } = props;

    const handleChangeValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        if (attribute.type === "YES_OR_NO") {
            let realValue: any = "";
            switch(value) {
                case "Yes":
                    realValue = true;
                    break;
                case "No":
                    realValue = false;
                    break;
            }
            if (value == "Any") {
                onChange({
                    attribute: attribute,
                    remove: true
                })
            }
            else {
                console.log('value baba')
                onChange({
                    attribute: attribute,
                    value: realValue
                })
            }
        }
        else {
            if (value == "Any") {
                onChange({
                    attribute: attribute,
                    remove: true
                })
            }
            else {
                console.log('value baba')
                onChange({
                    attribute: attribute,
                    value: value
                })
            }
        }
    }

    const getValue = () => {
        const value = values.find(e => e.subcategoryAttributeId == attribute.id);
        if (!value) return;
        if (value.type == "YES_OR_NO") {
            return value.value ? 'Yes' : 'No';
        }
        return value.value;
    }

    return (
        <div>
            <Select
               onChange={handleChangeValue}
               value={getValue()}
            >
                {hasAny && (
                    <option value="Any" key="any">
                        Any
                    </option>
                )}
                {customValues ? (
                    customValues.map(e => (
                        <option key={e} value={e}>
                            {e}
                        </option>
                    ))
                ) : (
                    attribute.choices.map(e => (
                        <option key={e.value} value={e.value}>
                            {e.value}
                        </option>
                    )))}
            </Select>
        </div>
    )

};
export default SubcategoryAttributeList;
