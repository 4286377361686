import { HiOutlineShoppingCart } from "react-icons/hi";
import { createStylesheet } from "src/utils/style";

type Props = {
    size?: 'small' | 'normal';
}

const AppLogo = ({ size = 'normal' }: Props) => {

    return (
        <div
            style={styles.root}
        >
            <div
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}
            >
                <div
                    style={{ ...(size === 'small' ? styles.logoDivSmall : styles.logoDivNormal), ...styles.logoDiv }}
                >
                    <HiOutlineShoppingCart
                        size={size === 'normal' ? 32 : 20}
                        color='#4299E1'
                        style={{
                            marginLeft: size === 'normal' ? 0 : 2
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    fontSize: size === 'normal' ? 18 : 14,
                    fontFamily: "Lucida Handwriting",
                    textAlign: "center",
                    fontWeight: 'bold',
                    color: '#4E7472'
                }}
            >
                Sangano
            </div>
            <div style={{ fontSize: size === 'normal' ? 12 : 10, color: '#4E7472', textAlign: 'center', fontFamily: 'Segoe Print' }}>
                Endless Possibilities
            </div>
        </div>
    )

};
export default AppLogo;
const styles = createStylesheet({
    root: {
        width: 150
    },
    logoDiv: {
        border: '1px solid #4299E1',
        alignItems: 'center',
        justifyContent: 'center'
    },
    logoDivSmall: {
        width: 35,
        height: 35,
        padding: 5,
        borderRadius: 35 / 2,
    },
    logoDivNormal: {
        width: 50,
        height: 50,
        padding: 10,
        borderRadius: 50 / 2,
    }
});

