import { StateModel, CityModel } from "../models/state.model";

export default class State {
    
    readonly id: string;
    readonly name: string;
    readonly cities: CityModel[];

    constructor(state: StateModel) {
        this.id = state.id;
        this.name = state.name;
        this.cities = state.cities;
    }
};
