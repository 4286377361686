import { action, makeAutoObservable, observable } from "mobx";
import PostService from "../services/post.service";
import { PostSearchModel, PostModel, PostCreateModel } from "../models/post.model";
import authStore from "./auth.store";


class PostStore {

    @observable private _posts: PostModel[] = [];
    @observable private _hasNext: boolean = false;
    @observable private _nextFilter: PostSearchModel = {};

    private _userPosts: { userId?: string, posts: PostModel[] } = {
        userId: '',
        posts: []
    }
    private _userHasNext: boolean = false;
    private _userNextFiter: PostSearchModel = {}

    constructor() {
        makeAutoObservable(this);
    }

    get getById() {
        return (id: string) => {
            const find = this._posts.find(e => e.id == id);
            if (find) return find;
            return this._userPosts.posts.find(e => e.id == id);
        }
    }

    get all() {
        return this._posts;
    }

    get hasNext() {
        return this._hasNext;
    }

    get userHasNext() {
        return this._userHasNext;
    }

    get userPosts() {
        return this._userPosts;
    }

    @action clear() {
        this._posts = [];
        this._nextFilter = {};
    }

    clearUserPosts() {
        this._userPosts = {
            userId: '',
            posts: []
        }
        this._userNextFiter = {}
    }

    /**
     * 
     * @param searchModel the filter
     * @param refresh if true replace old data by the new data (if param `mode` is `user-posts` then this param is ignored)
     * @param mode 
     */
    async search(searchModel: PostSearchModel, refresh: boolean = true, mode: 'user-posts' | 'all' = 'all') {
        const res = await PostService.search(searchModel);
        const data = res.data;
        if (Array.isArray(data.results)) {
            const newPosts = data.results.map(e => e);
            if (mode === 'all') {
                this._posts = refresh ? newPosts : [...this._posts, ...newPosts];
                this._hasNext = data.hasNext;
                this._nextFilter = data.filter;
            }
            else {
                let allPost: PostModel[] = [];
                if (searchModel.userId == this._userPosts.userId) {
                    allPost = [...this._userPosts.posts, ...newPosts];
                }
                else {
                    allPost = newPosts;
                }
                this._userPosts = {
                    userId: searchModel.userId,
                    posts: refresh ? newPosts : allPost
                }
                this._userHasNext = data.hasNext;
                this._userNextFiter = data.filter;
            }
        }
    }

    @action async loadFavourites() {
        // this.clear();
        const res = await PostService.getFavourites();
        if (res.data) {
            this._posts = res.data;
        }
    }

    @action async fetchById(id: string) {
        const res = await PostService.getById(id);
        const data = res.data;
        if (data) {
            this._posts = this._posts.filter(e => e.id != id);
            this._posts = this._posts.concat(data);
        }
    }

    @action async getRelated(postId: string) {
        const res = await PostService.getRelated(postId);
        const data = res.data;
        if (Array.isArray(data)) {
            // filter out all posts which was already stored
            this._posts = this._posts.filter(e => !data.find(d => d.id == e.id))
            this._posts = this._posts.concat(data);
        }
        return data;
    }

    @action async create(model: PostCreateModel) {
        await PostService.create(model);
        if (model.isActive) {
            authStore.increaseActivesPosts();
        }
        else {
            authStore.increaseInactivePosts();
        }
    }

    @action async update(postId: string, model: PostCreateModel) {
        const res = await PostService.update(postId, model);
        if (res.data) {
            this._posts = this._posts.filter(e => e.id != postId);
            this._posts = this._posts.concat(res.data);
        }
    }

    @action async uploadImage(postId: string, data: string) {
        const res = await PostService.uploadImage(postId, data);
        if (res.data) {
            this._posts = this._posts.filter(e => e.id != postId);
            this._posts = this._posts.concat(res.data);
            return res.data;
        }
    }

    @action async deleteImage(postId: string, imageId: string) {
        await PostService.deleteImage(postId, imageId);
        const old = this._posts.find(e => e.id == postId);
        if (!old) return;
        old.images = old.images.filter(e => e.id != imageId);
        this._posts = this._posts.filter(e => e.id != postId);
        this._posts = this._posts.concat(old);
    }

    @action async toggleSave(postId: string) {
        await PostService.toggleSave(postId);
        const newPosts: PostModel[] = [];
        this._posts.forEach(p => {
            if (p.id == postId) {
                p.isSaved = !p.isSaved;
            }
            newPosts.push(p);
        });
        this._posts = newPosts;
        authStore.fetchProfile();
    }

    async next() {
        if (!this._hasNext) return;
        await this.search(this._nextFilter, false);
    }

    async userNext() {
        if (!this._userNextFiter) return;
        await this.search(this._userNextFiter, false, 'user-posts')
    }
}
export default new PostStore();
