import { Box, Heading, Avatar, Button, Text } from "@chakra-ui/react";
import moment from "moment";
import { AiOutlineMessage, AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import { FaPhoneAlt } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import { IoMailSharp } from "react-icons/io5";
import { PostModel } from "src/models/post.model";
import { UserModel, UserProfileModel } from "src/models/user.model";
import { createStylesheet } from "src/utils/style";

type Props = {
    currentUser?: UserProfileModel,
    post: PostModel,
    onUpdate: () => void;
    onClickMessage: () => void;
    onClickWhatsapp: () => void;
    onDelete: () => void;
    onClickUser: () => void;
}


const PostSeller = (props: Props) => {

    const { post, onClickMessage, onClickWhatsapp, onClickUser, currentUser } = props;

    return (
        <div>
            <Box
                borderWidth={2}
                style={{ padding: 10 }}
            >
                <Heading size='md'>
                    Seller description
                </Heading>

                <div style={styles.avatarRow}>
                    <Avatar
                        name={`${post.user.firstName} ${post.user.lastName}`}
                        src={post.user.profile}
                    />
                    <div
                        style={{
                            marginLeft: 10
                        }}
                    >
                        <div
                            onClick={onClickUser}
                            style={{ cursor: 'pointer' }}
                        >
                            <Text className="ellipse" style={{ fontWeight: 'bolder' }}>
                                {post.user.firstName} {post.user.lastName}
                            </Text>
                            <Text style={styles.memberSince}>
                                Member since {moment(post.user.dateCreated).calendar()}
                            </Text>
                        </div>

                        <div>
                            {post.user.email || post.user.phone ? (
                                <Heading size='sm' style={{ marginTop: 10 }}>
                                    Contacts
                                </Heading>
                            ) : ''}

                            <div style={{ marginTop: 10 }}>
                                {post.user.phone && (
                                    <div style={styles.row}>
                                        <FaPhoneAlt size={14} />
                                        <Text style={styles.contact}>
                                            {post.user.phone}
                                        </Text>
                                    </div>
                                )}

                                {post.user.email && (
                                    <div style={styles.row}>
                                        <IoMailSharp size={14} />
                                        <Text style={styles.contact}>
                                            {post.user.email}
                                        </Text>
                                    </div>
                                )}
                            </div>
                        </div>
                        {currentUser && currentUser.id == post.user.id ? (
                            <>
                                <Button
                                    leftIcon={<AiFillEdit size={22} />}
                                    colorScheme="blue"
                                    variant="solid"
                                    style={{ marginTop: 10 }}
                                    onClick={props.onUpdate}
                                >
                                    Update
                                </Button>
                                <Button
                                    leftIcon={<AiOutlineDelete size={22} />}
                                    variant="solid"
                                    colorScheme="red"
                                    style={{ marginTop: 10 }}
                                    onClick={props.onDelete}
                                >
                                    Delete
                                </Button>
                            </>
                        ) : (
                            <>
                                <div>
                                    <Button
                                        leftIcon={<ImWhatsapp />}
                                        colorScheme="green"
                                        variant="solid"
                                        style={{ marginTop: 5 }}
                                        onClick={onClickWhatsapp}
                                    >
                                        Whatsapp
                                    </Button>
                                </div>
                            </>
                        )}

                    </div>
                </div>


            </Box>

        </div>
    )

};
export default PostSeller;

const styles = createStylesheet({
    avatarRow: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
        //alignItems: 'center'
    },
    memberSince: {
        color: 'gray',
        fontSize: 14
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    contact: {
        fontSize: 13,
        marginLeft: 10
    }
});
