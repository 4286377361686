import { PostModel } from "src/models/post.model";
import { formatMoney } from "src/services/util";
import { createStylesheet } from "src/utils/style";
import { GoLocation } from "react-icons/go"
import { Box } from "@chakra-ui/react";
import moment from "moment";
import { AiOutlineCalendar } from "react-icons/ai";
import { BsFillHeartFill, BsHeart } from "react-icons/bs";

type Props = {
    post: PostModel,
    onClick: (post: PostModel) => void;
}

const PostCardVertical = (props: Props) => {

    const { post } = props;

    const handleClick = () => {
        props.onClick(post);
    }

    return (
        <Box
            style={styles.root}
            onClick={handleClick}
        >
            <img
                src={post.images ? post.images[0].url : ""}
                style={styles.img}
            />
            <div style={styles.content}>
                <span style={styles.title} className="ellipse two-lines">
                    {post.title}
                </span>
                <span style={styles.price}>
                    ${formatMoney(post.price)}
                </span>

                <div
                    style={{
                        display: 'flex',
                        marginTop: 2,
                        alignItems: 'center',
                        color: 'gray',
                        justifyContent: 'space-between'
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AiOutlineCalendar color="gray" />
                        <span style={{ ...styles.description, marginLeft: 5 }}>
                            {moment(post.dateCreated).fromNow()}
                        </span>
                    </div>

                    <div>
                        <a href="#">
                            {post.isSaved ? (
                                <BsFillHeartFill />
                            ) : (
                                <BsHeart />
                            )}
                        </a>
                    </div>
                </div>


                <div style={styles.row}>
                    <GoLocation size={12} color='gray' />
                    <span
                        className="ellipse two-lines"
                        style={{
                            marginLeft: 5, fontSize: 12, color: 'gray'
                        }}
                    >
                        {post.stateName}, {post.cityName}
                    </span>
                </div>
            </div>
        </Box>
    )

};
export default PostCardVertical;
const styles = createStylesheet({
    root: {
        paddingLeft: 5,
        marginTop: 15,
        display: "flex",
        flexDirection: "row"
    },
    content: {
        display: "flex",
        flexDirection: "column",
        marginLeft: 10
    },
    img: {
        width: 150,
        height: 120
    },
    title: {
        fontSize: 18
    },
    description: {
        color: "gray",
        fontSize: 13
    },
    price: {
        fontSize: 20,
        fontWeight: "bold",
        marginTop: 5
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    }
})