import { Text, InputProps, Input } from "@chakra-ui/react"
import { Formik, Form, useField, FieldProps, FieldHookConfig } from 'formik';
import { FC } from 'react';

interface Props extends InputProps {
    error?: string,
    bg?: string,
    onValueChanged: (value: string) => void;
}

const FormInput = (props: Props) => {

    const { error, bg = 'gray.100' } = props;
    
    return (
        <>
            <Input
                {...props}
                onChange={e => props.onValueChanged(e.target.value)}
                bg={bg}
                color={'black'}
                _placeholder={{
                    color: 'gray.500',
                }}
                focusBorderColor={error != undefined ? 'red' : 'blue.500'}
                isInvalid={error != undefined}
            />
            {error && (
                <Text
                    style={{
                        color: 'red'
                    }}
                >
                    {error}
                </Text>
            )}
        </>
    );
}
export default FormInput;
