import { filter, Tag, TagLabel } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { formatMoney } from "src/services/util";

type Props = {
    minPrice?: number,
    maxPrice?: number,
    onClear: () => void;
}

const PostPriceFilterTag = (props: Props) => {

    const { minPrice: minValue, maxPrice: maxValue, onClear } = props;

    const renderValue = () => {
        if (minValue && maxValue) {
            return minValue == maxValue ? minValue : `From ${minValue} to ${maxValue}`
        }
        else if (minValue && !maxValue) {
            return `Atleast $${formatMoney(minValue)}`
        }
        else {
            return `Up to $${formatMoney(maxValue)}`
        }
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: 10
            }}
        >
            <Tag size="md" variant="subtle" colorScheme="pink" style={{ padding: 10 }}>
                <TagLabel>
                    Price: {renderValue()}
                </TagLabel>

                <a href="#" onClick={onClear}>
                    <AiOutlineClose style={{ marginLeft: 5 }} />
                </a>
            </Tag>
        </div>
    )

};
export default PostPriceFilterTag;
