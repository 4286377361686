import { observable, action } from "mobx";
import { MessageModel, ChatModel } from "../models/chat.model";
import ChatService from "../services/chat.service";


type Pagination = {
    hasNext: boolean,
    nextPage: number
}


class ChatStore {

    @observable _chats: ChatModel[] = [];
    @observable _messages: MessageModel[] = [];
    @observable _pageIndex = 1;
    @observable _chatPagination: Pagination = { hasNext: true, nextPage: 1 };
    private _chatId = "";

    get chats() {
        return this._chats;
    }

    get currentChat() {
        return this._chats.find(e => e.id == this._chatId);
    }

    get messages() {
        return this._messages;
    }

    @action setChat(chatId: string) {
        this._messages = [];
        this._chatId = chatId;
    }

    @action push(chat: ChatModel) {
        this._chats = this.chats.concat(chat);
    }

    @action clear() {
        this._chats = [];
        this._chatPagination = {
            hasNext: true,
            nextPage: 1
        }
        this._pageIndex = 1;
        this.setChat("");
    }

    @action mergeMessages(data: MessageModel[]) {
        if (data) {
            this._messages = this._messages.filter(e => !data.find(w => w.id == e.id));
            this._messages = this._messages.concat(data);
        }
    }

    @action async loadChats() {
        const res = await ChatService.get(this._pageIndex, 25);
        const data = res.data;
        if (data) {
            this._chatPagination = {
                hasNext: data.hasNext,
                nextPage: data.page
            }
            this._chats = [...this._chats, ...data.results]
        }
        this._pageIndex += 1;
    }

    @action async fetchChat(chatId: string) {
        const res = await ChatService.fetch(chatId);
        if (res.data) {
            // fetch push the chat at first position
            const old = this._chats.filter(e => e.id != res.data.id);
            this._chats = [];
            this._chats = this._chats.concat(res.data);
            this._chats = this._chats.concat(old);
        }
    }

    @action async createChat(chatId: string, postId: string, message: string) {
        const res = await ChatService.createChat(postId, message);
        const data = res.data;
        if (data) {
            // remove temp chat

            this._chats = this._chats.filter(e => e.id != chatId);
            this._chats = this._chats.concat(data);
            this.setChat(data.id);
            this.loadMessages();
            return data.id;
        }
    }

    @action async sendMessage(chatId: string, message: string) {
        const res = await ChatService.sendMessage(chatId, message);
        const data = res.data;
        if (data) {
            this._messages = this._messages.concat(data);
        }
    }

    @action async loadMessages() {
        const res = await ChatService.getMessages(this._chatId);
        const data = res.data;
        this.mergeMessages(data);
    }

    // Get 25 recent messages
    @action async loadUnreadMessages() {
        const res = await ChatService.getUnreadMessages(this._chatId)
        const data = res.data;
        this.mergeMessages(data);
    }
};
export default new ChatStore();
