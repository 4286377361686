import { Box, Heading, Image } from "@chakra-ui/react";
import moment from "moment";
import { ChatModel, ChatSenderModel, MessageModel } from "src/models/chat.model";
import { createStylesheet } from "src/utils/style";
import ChatMessageImages from "./chat.message.images";

type Props = {
    currentUserId: string,
    messages: MessageModel[]
}

const ChatMessages = (props: Props) => {

    const { messages, currentUserId } = props;

    const grouped = () => {
        let grouped: {
            dateSent: string,
            timestamp: number,
            messages: MessageModel[]
        }[] = [];

        for (const message of messages) {
            const dateSent = moment(message.dateSent)
                .calendar(null, {
                    lastDay: '[Yesterday]',
                    sameDay: '[Today]',
                    nextDay: '[Tomorrow]',
                    lastWeek: 'dddd',
                    nextWeek: 'dddd',
                    sameElse: 'll'
                });
            const findIndex = grouped.findIndex(e => e.dateSent == dateSent);
            if (findIndex != -1) {
                grouped[findIndex].messages.push(message);
            }
            else {
                grouped.push({
                    dateSent: dateSent,
                    timestamp: Date.parse(message.dateSent),
                    messages: [message]
                });
            }
        }
        grouped = grouped.sort((a, b) => a.timestamp - b.timestamp);

        /*for(const x of grouped) {
            console.log(x.dateSent, ' || ', x.messages[0].dateSent, '%%', x.timestamp)
        }*/

        return grouped;
    }

    return (
        <div>
            {grouped().map(grouped => (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Box
                            shadow="lg"
                            borderRadius='lg'
                        >
                            <Heading
                                style={styles.dateSent}
                                backgroundColor='pink.400'
                                size='sm'
                            >
                                {grouped.dateSent}
                            </Heading>
                        </Box>
                    </div>
                    {grouped.messages.map(e => (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: e.senderId == currentUserId ? 'flex-end' : 'flex-start'
                            }}
                        >
                            <Box
                                style={
                                    e.senderId == currentUserId ? styles.send : styles.received
                                }
                                shadow="lg"
                                rounded="lg"
                            >
                                <ChatMessageImages
                                    images={e.attachments}
                                />
                                {e.message}
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <span style={{ color: 'gray', fontSize: 12 }}>
                                        {moment(e.dateSent).format('HH:mm')}
                                    </span>
                                </div>
                            </Box>
                        </div>
                    ))}
                </>
            ))}
        </div>
    )

};
export default ChatMessages;
const styles = createStylesheet({
    root: {

    },
    dateSent: {
        textAlign: 'center',
        marginTop: 10,
        position: 'sticky',
        padding: 10,
        color: '#fff',
        borderRadius: 10
    },
    send: {
        background: "#DCF8C6",
        padding: 10,
        marginTop: 10,
        marginRight: 10,
        maxWidth: 300,
        flexDirection: 'column'
    },
    received: {
        background: "#fff",
        padding: 10,
        marginTop: 10,
        marginLeft: 10,
        maxWidth: 300,
        display: "flex",
        flexDirection: 'column'
    },
});

