import apiService from "./api.service";
import { StateModel } from "../models/state.model";

export default class StateService {

    static fetch() {
        return apiService.get<StateModel[]>("states");
    }
}

