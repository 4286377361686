import { Box, Textarea, Button, Heading, Text, background, Menu, MenuButton, MenuItem, MenuList, Checkbox } from "@chakra-ui/react";
import PillPity from "pill-pity";
import { useRef, useEffect, useState } from "react";
import { ChatModel, MessageModel, ChatSenderModel } from "src/models/chat.model";
import authStore from "src/store/auth.store";
import uiStore from "src/store/ui.store";
import { createStylesheet } from "src/utils/style";
import useWindowDimensions from "src/window.dimension";
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { appStyles } from "src/utils/app.styles";
import { BiImageAdd } from 'react-icons/bi'
import ErrorModal from "../error.modal";
import { AiOutlineClose } from 'react-icons/ai';
import ChatAttachmentSend from "./chat.attachment.send";
import faker from 'faker';
import ChatMessages from "./chat.message";


type Props = {
    chat: ChatModel,
    screenSize?: 'mobile' | 'desktop',
    onBackClick?: () => void;
}

const Chat = (props: Props) => {

    const { chat, screenSize = 'desktop' } = props;
    
    const { height } = useWindowDimensions();
    const { headerHeight } = uiStore;
    const { user } = authStore;

    const currentUserId = 'hello';

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [attachments, setAttachements] = useState<File[]>([]);
    const [messages, setMessages] = useState<MessageModel[]>([]);

    const messageRef = useRef<HTMLDivElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const imgs = [
        'https://images.unsplash.com/photo-1619953942547-233eab5a70d6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=358&q=80',
        'https://images.unsplash.com/photo-1619953983867-eb8426ab271e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=358&q=80',
        'https://images.unsplash.com/photo-1578353022142-09264fd64295?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=358&q=80',
        'https://images.unsplash.com/photo-1485965120184-e220f721d03e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80',
        'https://images.unsplash.com/photo-1511919884226-fd3cad34687c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
    ]

    useEffect(() => {

        uiStore.setShowFooter(false);

        chatScrollBottom();
        messageRef.current?.scrollIntoView({ behavior: 'smooth' });
        messageRef.current?.addEventListener('scroll', handleScroll);

        const t: MessageModel[] = [];
        for (var i = 0; i < 100; i++) {
            const m: MessageModel = {
                id: faker.random.alphaNumeric(),
                message: faker.random.words(faker.datatype.number({ min: 1, max: 10 })),
                attachments: faker.datatype.boolean() ? [] : faker.random.arrayElements(imgs).map(e => ({
                    url: e
                })),
                dateSent: faker.date.recent(20).toISOString(),
                dateRead: '',
                senderId: faker.random.arrayElement([currentUserId, '234898883e'])
            }
            t.push(m);
        }

        setMessages(t);

        return () => {
            uiStore.setShowFooter(true);
            messageRef.current?.removeEventListener("scroll", handleScroll);
        }

    }, []);

    useEffect(() => {

        chatScrollBottom();

    }, [messages]);

    const handleScroll = (e: any) => {
        //console.log('hello ', e.srcElement.scrollTop)
    }

    const chatScrollBottom = () => {
        messageRef.current?.scrollTo({
            top: messageRef.current.scrollHeight,
            behavior: 'smooth'
        })
    }

    const handleSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !e.target.files.length) return;
        const file = e.target.files[0];
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
        if (!validImageTypes.includes(file['type'])) {
            // invalid file type code goes here.
            setError('We currenly only accept images (jpeg, gif and png)')
            return;
        }
        var filesize = Number(((file.size / 1024) / 1024).toFixed(4)); // MB
        if (filesize > 5) {
            setError('The file cannot be larger than 5 MB')
            return;
        }
        if (attachments.length > 4) {
            setError('You can only attached 5 images maximun in a single message.');
            return;
        }
        setAttachements([...attachments, file]);
    }

    const handleOpenImagePicker = () => {
        fileInputRef?.current?.click();
    }

    const handleRemoveAttachments = (file: File) => {
        setAttachements(attachments.filter(e => e != file));
    }

    const test = () => {
        chatScrollBottom()
    }

    if (!chat) return (
        <div>No message selected</div>
    )

    return (
        <PillPity
            style={{
                ...styles.root,
                height: screenSize == 'desktop' ? height - headerHeight : height
            }}
            pattern="topography"
        >
            <ErrorModal
                message={error}
                onClose={() => setError('')}
            />
            <div>
                <div
                    style={{
                        ...styles.header, background: screenSize == 'desktop' ? '#fff' : '#EBEEEF',
                    }}
                >
                    <div
                        style={appStyles.rowCenter}
                    >
                        {screenSize == 'mobile' && (
                            <AiOutlineArrowLeft
                                size={24}
                                onClick={() => props.onBackClick && props.onBackClick()}
                            />
                        )}
                        <div
                            style={{
                                ...appStyles.rowCenter, marginLeft: 10
                            }}
                        >
                            {chat.post.images.length && (
                                <img
                                    src={chat.post.images[0].url}
                                    style={{
                                        width: 40,
                                        height: 40,
                                        objectFit: 'cover'
                                    }}
                                />
                            )}

                            <div style={{ marginLeft: 10 }}>
                                <Heading size='md' style={{ textTransform: 'uppercase' }}>
                                    {chat.post.user.firstName} {chat.post.user.lastName}
                                </Heading>
                                <Text>
                                    {chat.post.title} ${chat.post.price}
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>

            <div
                ref={messageRef}
                style={styles.messages}
            >
                <ChatMessages
                    messages={messages}
                    currentUserId={currentUserId}
                />
            </div>

            {attachments.length > 0 && (
                <div
                    style={styles.attachments}
                >
                    {attachments.map((e, index) => (
                        <>
                            <ChatAttachmentSend
                                file={e}
                                onRemove={handleRemoveAttachments}
                            />
                            {index != attachments.length - 1 && (
                                <hr />
                            )}
                        </>
                    ))}
                </div>
            )}

            <Box
                style={styles.inputDiv}
                shadow="lg"
                rounded="lg"
            >
                <Textarea
                    placeholder="Type in your message"
                    style={{
                        height: 40,
                        maxHeight: 120
                    }}
                    onChange={e => setMessage(e.target.value)}
                    value={message}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <BiImageAdd
                        size={24}
                        onClick={handleOpenImagePicker}
                    />
                    <input id="myInput"
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        accept="image/png, image/gif, image/jpeg"
                        onChange={handleSelectImage}
                    />
                    <div
                        style={{ padding: 10, display: "flex", justifyContent: "center" }}
                    >
                        <Button
                            colorScheme="blue"
                            onClick={test}
                            size='sm'
                            disabled={message.trim() == '' && attachments.length == 0}
                        >
                            Send
                        </Button>
                    </div>
                </div>
            </Box>
        </PillPity>

    )

};
export default Chat;

const styles = createStylesheet({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    header: {
        padding: 15,
        background: '#fff'
    },
    messages: {
        overflowY: 'scroll',
        paddingBottom: 50
    },
    section: {
        display: "flex",
    },
    sectionReceived: {
        display: "flex",
        justifyContent: "flex-end"
    },
    send: {
        background: "#fff",
        padding: 10,
        marginTop: 10,
        marginLeft: 10,
        maxWidth: 300
    },
    received: {
        background: "#DCF8C6",
        padding: 10,
        marginTop: 10,
        marginLeft: 10,
        maxWidth: 300,
        display: "flex"
    },
    inputDiv: {
        display: "flex",
        flexDirection: 'column',
        //margin: 10,
        marginTop: 10,
        height: 150,
        padding: 10,
        background: "#fff"
    },
    attachments: {
        background: '#fff',
        padding: 10,
        margin: 10,
        marginTop: 10,
        //position: 'relative',
        //bottom: 30,
        //height: ' 100vh',
        height: '1000vh',
        border: '2px solid pink',
        borderRadius: 25,
        display: 'flex',
        //flex: 1,
        flexDirection: 'column',
        overflowY: 'auto'
    }
});
