import { Box, Heading } from "@chakra-ui/react";
import { PostModel } from "src/models/post.model";
import { formatMoney } from "src/services/util";
import { createStylesheet } from "src/utils/style";
import { GoLocation } from "react-icons/go"
import { AppColors } from "src/constants";
import moment from "moment";
import { AiOutlineCalendar } from "react-icons/ai";
import { BsFillHeartFill, BsHeart } from "react-icons/bs";

type Props = {
    post: PostModel,
    onClick: (post: PostModel) => void; 
    onToggleSave?: () => void;
}

const PostCard = (props: Props) => {

    const { post } = props;

    const handleClick = () => {
        props.onClick(post)
    }

    return (
        <Box
            style={styles.root}
            //boxShadow='xs'
            borderWidth={1}
            onClick={handleClick}
        >
            <img
                src={post.images ? post.images[0].url : ""}
                style={styles.img}
            />
            <div style={styles.content}>
                <span
                    className="ellipse two-lines"
                >
                    {post.title}
                </span>

                <span style={styles.price}>
                    ${formatMoney(post.price)}
                </span>

                <div>
                    <div
                        style={{
                            display: 'flex',
                            marginTop: 2,
                            alignItems: 'center',
                            color: 'gray',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <AiOutlineCalendar color="gray" />
                            <span style={{ ...styles.description, marginLeft: 5 }}>
                                {moment(post.dateCreated).fromNow()}
                            </span>
                        </div>

                        <div>
                            <a href="#">
                                {post.isSaved ? (
                                    <BsFillHeartFill />
                                ) : (
                                    <BsHeart />
                                )}
                            </a>
                        </div>
                    </div>
                    
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <GoLocation size={12} color="gray" />
                        <span
                            className="ellipse two-lines"
                            style={{
                                marginLeft: 5,
                                fontSize: 12,
                                color: 'gray'
                            }}
                        >
                            {post.stateName}, {post.cityName}
                        </span>
                    </div>
                </div>
            </div>
        </Box>
    )

};
export default PostCard;
const styles = createStylesheet({
    root: {
        width: 250,
        //border: "2px solid #EFEFEF",
        marginLeft: 15,
        marginTop: 12,
        borderRadius: 5,
        //padding: 10
    },
    content: {
        flexDirection: "column",
        display: "flex",
        padding: 12,
        height: 150,
        justifyContent: 'space-between',
        //border: '2px solid #eee'
    },
    img: {
        width: "100%",
        height: 140,
        objectFit: 'contain'
    },
    title: {
        fontSize: 18
    },
    description: {
        color: "gray",
        fontSize: 13
    },
    price: {
        fontSize: 20,
        fontWeight: "bold",
        //color: AppColors.secondary
    }
});

