import { Heading, NumberInput, NumberInputField, Text } from "@chakra-ui/react";
import { SubCategoryAttributeModel } from "src/models/category.model";
import {ChangeSubcategoryFilterModel} from "../posts/post.search.modal";
import React, {useState} from "react";
import { PostAttributeSearchModel } from "src/models/post.model";

type Props = {
    attribute: SubCategoryAttributeModel,
    values: PostAttributeSearchModel[],
    onChange: (value: ChangeSubcategoryFilterModel) => void;
}

const SubcategoryAttributeNumber = (props: Props) => {

    const { attribute, onChange, values } = props;
    //const [min, setMin] = useState<number>();
    //const [max, setMax] = useState<number>();

    const handleChangeMin = (e: React.ChangeEvent<HTMLInputElement>) => {
        //const value = e.target.value;
        //setMin(value ? Number(value) : undefined);
        onChange({
            attribute: attribute,
            min: e.target.value ? Number(e.target.value) : undefined,
            max: getMax()
        })
    }

    const handleChangeMax = (e: React.ChangeEvent<HTMLInputElement>) => {
        //const value = e.target.value;
        //setMax(value ? Number(value) : undefined);
        onChange({
            attribute: attribute,
            min: getMin(),
            max: e.target.value ? Number(e.target.value) : undefined
        })
    }

    const getMin = () => {
        const value = values.find(e => e.subcategoryAttributeId == attribute.id);
        if (!value) return;
        return value.minValue;
    }

    const getMax = () => {
        const value = values.find(e => e.subcategoryAttributeId == attribute.id);
        if (!value) return;
        return value.maxValue;
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            }}
        >
            <div>
                <NumberInput
                    min={0}
                    value={getMin()}
                    placeholder="Min"
                >
                    <NumberInputField
                        placeholder="Min"
                        onChange={handleChangeMin}
                    />
                </NumberInput>
            </div>

            <div>
                <NumberInput
                    min={0}
                    placeholder="Max"
                    value={getMax()}
                    style={{ marginLeft: 10 }}
                >
                    <NumberInputField
                        placeholder="Max"
                        onChange={handleChangeMax}
                    />
                </NumberInput>
            </div>
        </div>
    )
};
export default SubcategoryAttributeNumber;
