import {
    Box,
    Flex,
    Stack,
    Heading,
    Text,
    Container,
    Input,
    Button,
    SimpleGrid,
    Avatar,
    AvatarGroup,
    useBreakpointValue,
    IconProps,
    Icon,
    Alert,
    AlertDescription,
    AlertIcon,
    CloseButton,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { Formik, Form, useFormik, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { SignupModel } from 'src/models/user.model';
import { useState } from 'react';
import FormInput from 'src/components/form/form.input';
import { min } from 'moment';
import authStore from 'src/store/auth.store';
import { RouterProps } from 'react-router';
import { AxiosError } from 'axios';


interface Props extends RouterProps {

}

const avatars = [
    {
        name: 'Ryan Florence',
        url: 'https://images.unsplash.com/photo-1519923834699-ef0b7cde4712?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80',
    },
    {
        name: 'Segun Adebayo',
        url: 'https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80',
    },
    {
        name: 'Kent Dodds',
        url: 'https://images.unsplash.com/photo-1558098329-a11cff621064?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=336&q=80',
    },
    {
        name: 'Prosper Otemuyiwa',
        url: 'https://images.unsplash.com/photo-1494976388531-d1058494cdd8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80',
    },
    {
        name: 'Christian Nwamba',
        url: 'https://images.unsplash.com/photo-1591047139829-d91aecb6caea?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=376&q=80',
    },
];

type AuthFormType = {
    username: string,
    password: string
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const fieldRequired = 'This field is required';
const FormSchema = Yup.object<Record<keyof AuthFormType, Yup.AnySchema>>({
    username: Yup.string().required(fieldRequired),
    password: Yup.string().required(fieldRequired)
})

const SignupPage = observer((props: Props) => {

    const initialValues: AuthFormType = {
        username: '',
        password: ''
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const handleSubmit = (value: AuthFormType) => {

        if (loading) return;
        setLoading(true);

        authStore.auth(value.username, value.password)
            .then(() => {
                props.history.push('/')
            })
            .catch((e: AxiosError<{ message: string }>) => {
                const err = e.response?.data.message;
                setError(err);
            })
            .finally(() => setLoading(false));
    };

    const handleClearError = () => {
        setError(undefined);
    }


    return (
        <Box position={'relative'}>
            <Container
                as={SimpleGrid}
                maxW={'7xl'}
                columns={{ base: 1, md: 2 }}
                spacing={{ base: 10, lg: 32 }}
                py={{ base: 10, sm: 20, lg: 32 }}>
                <Stack spacing={{ base: 10, md: 20 }}>
                    <Heading
                        lineHeight={1.1}
                        fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '6xl' }}>
                        Welcome{' '}
                        Back
                    </Heading>
                    <Stack direction={'row'} spacing={4} align={'center'}>
                        <AvatarGroup>
                            {avatars.map((avatar) => (
                                <Avatar
                                    key={avatar.name}
                                    name={avatar.name}
                                    src={avatar.url}
                                    size={useBreakpointValue({ base: 'md', md: 'lg' })}
                                    position={'relative'}
                                    zIndex={2}
                                    _before={{
                                        content: '""',
                                        width: 'full',
                                        height: 'full',
                                        rounded: 'full',
                                        transform: 'scale(1.125)',
                                        bgGradient: 'linear(to-bl, red.400,pink.400)',
                                        position: 'absolute',
                                        zIndex: -1,
                                        top: 0,
                                        left: 0,
                                    }}
                                />
                            ))}
                        </AvatarGroup>
                        <Text fontFamily={'heading'} fontSize={{ base: '4xl', md: '6xl' }}>
                            +
                        </Text>
                        <Flex
                            align={'center'}
                            justify={'center'}
                            fontFamily={'heading'}
                            fontSize={{ base: 'sm', md: 'lg' }}
                            bg={'gray.800'}
                            color={'white'}
                            rounded={'full'}
                            width={useBreakpointValue({ base: '44px', md: '60px' })}
                            height={useBreakpointValue({ base: '44px', md: '60px' })}
                            position={'relative'}
                            _before={{
                                content: '""',
                                width: 'full',
                                height: 'full',
                                rounded: 'full',
                                transform: 'scale(1.125)',
                                bgGradient: 'linear(to-bl, orange.400,yellow.400)',
                                position: 'absolute',
                                zIndex: -1,
                                top: 0,
                                left: 0,
                            }}>
                            More
                        </Flex>
                    </Stack>
                </Stack>
                <Stack
                    bg={'gray.50'}
                    rounded={'xl'}
                    p={{ base: 4, sm: 6, md: 8 }}
                    spacing={{ base: 8 }}
                    maxW={{ lg: 'lg' }}>
                    <Stack spacing={4}>
                        <Heading
                            color={'gray.800'}
                            lineHeight={1.1}
                            fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
                            Sign up into your account
                            <Text
                                as={'span'}
                                bgGradient="linear(to-r, red.400,pink.400)"
                                bgClip="text">
                                !
                            </Text>
                        </Heading>

                    </Stack>
                    <Box as={'form'} mt={10}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={FormSchema}
                            onSubmit={handleSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue
                                /* and other goodies */
                            }) => (
                                <Stack spacing={4}>
                                    {error && (
                                        <>
                                            <Alert status="error">
                                                <AlertIcon />
                                                <AlertDescription>
                                                    {error}
                                                </AlertDescription>
                                                <CloseButton
                                                    position="absolute"
                                                    right="8px"
                                                    top="8px"
                                                    onClick={handleClearError}
                                                />
                                            </Alert>
                                        </>
                                    )}
                                    <FormInput
                                        value={values.username}
                                        error={errors.username}
                                        placeholder='Username'
                                        onValueChanged={(e) => setFieldValue('username', e)}
                                    />
                                    <FormInput
                                        value={values.password}
                                        error={errors.password}
                                        placeholder='Password'
                                        type='password'
                                        onValueChanged={(e) => setFieldValue('password', e)}
                                    />
                                    <Button
                                        fontFamily={'heading'}
                                        mt={8}
                                        w={'full'}
                                        bgGradient="linear(to-r, red.400,pink.400)"
                                        color={'white'}
                                        _hover={{
                                            bgGradient: 'linear(to-r, red.400,pink.400)',
                                            boxShadow: 'xl',
                                        }}
                                        isLoading={loading}
                                        onClick={() => handleSubmit()}
                                    >
                                        Sign in
                                    </Button>
                                </Stack>
                            )}
                        </Formik>

                        <div
                            style={{
                                padding: 10,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Text
                                style={{
                                    textDecoration: 'underline',
                                    color: '#2B6CB0',
                                    cursor: 'pointer'
                                }}
                                onClick={() => props.history.push('/signup')}
                            >
                                Sign up instead
                            </Text>
                        </div>

                    </Box>
                </Stack>
            </Container>
            <Blur
                position={'absolute'}
                top={-10}
                left={-10}
                style={{ filter: 'blur(70px)' }}
            />
        </Box>
    );
});
export default SignupPage;

export const Blur = (props: IconProps) => {
    return (
        <Icon
            width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
            zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
            height="560px"
            viewBox="0 0 528 560"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="71" cy="61" r="111" fill="#F56565" />
            <circle cx="244" cy="106" r="139" fill="#ED64A6" />
            <circle cy="291" r="139" fill="#ED64A6" />
            <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
            <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
            <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
            <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
        </Icon>
    );
};

