import { Alert, AlertIcon, AlertDescription } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Box, Heading, Stack } from "@chakra-ui/layout";
import { Checkbox, Input, InputGroup, InputLeftElement, Text, Select } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { Formik } from "formik";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { RouterProps } from "react-router-dom";
import ErrorModal from "src/components/error.modal";
import FormInput from "src/components/form/form.input";
import FormTextArea from "src/components/form/form.textarea";
import PostFormAttributeSection from "src/components/posts/form/post.form.attribute.section";
import PostFormImageSection from "src/components/posts/form/post.form.image.section";
import PostFormLocationSection from "src/components/posts/form/post.form.location.section";
import { CategoryModel, SubCategoryAttributeCreateModel, SubCategoryModel } from "src/models/category.model";
import { PostCreateModel, PostModel } from "src/models/post.model";
import { CityModel, StateModel } from "src/models/state.model";
import categoryStore from "src/store/category.store";
import postStore from "src/store/post.store";
import State from "src/store/state";
import stateStore from "src/store/state.store";
import subcategoryStore from "src/store/subcategory.store";
import { createStylesheet } from "src/utils/style";
import { toBase64 } from "src/utils/util";
import * as yup from 'yup';

interface Props {
    withImages: boolean,
    initialPost: PostModel,
    loading: boolean,
    categories: CategoryModel[],
    states: StateModel[],
    onSubmit: (post: PostCreateModel) => void;
}

type FormType = {
    "title": string,
    "description"?: string,
    active: boolean,
    "price": number,
}

const fieldRequired = 'This field is required';
const FormSchema = yup.object<Record<keyof FormType, yup.AnySchema>>({
    title: yup.string().min(3, 'The title is too short').required(fieldRequired),
    price: yup.number().min(0, 'Invalid price').required(fieldRequired),
    description: yup.string(),
    active: yup.boolean()
});

const PostForm = (props: Props) => {

    const initialValues: FormType = {
        title: '',
        description: '',
        price: 0,
        active: true
    }

    const { initialPost, categories, states } = props;

    const [selectedCategoryId, setSelectedCategoryId] = useState<string>();
    const [selectedSubcategory, setSelectedSubcategory] = useState<SubCategoryModel>();
    const [error, setError] = useState<string>('');
    const [attachments, setAttachements] = useState<File[]>([]);
    const [attributes, setAttributes] = useState<SubCategoryAttributeCreateModel[]>([]);

    const [selectedState, setSelectedState] = useState<State>();
    const [selectedCity, setSelectedCity] = useState<CityModel>();

    useEffect(() => {

        if (initialPost) {
            initialValues.title = initialPost.title;
            initialValues.description = initialPost.description;
            initialValues.active = initialPost.isActive;
            initialValues.price = initialPost.price;

            setAttributes(initialPost.attributes);
           
            const selectedSubcategory = getSubcategory(initialPost.subCategoryId);
            if (selectedSubcategory) {
                setSelectedCategoryId(selectedSubcategory.categoryId);
                setSelectedSubcategory(selectedSubcategory);
            }

            const selectedCity = getCity(initialPost.cityId);
            if (selectedCity) {
                setSelectedCity(selectedCity);
                setSelectedState(states.find(e => e.cities.find(e => e.id == selectedCity.id)));
            }
        }
        else {
            setAttributes([]);
            setSelectedSubcategory(undefined)
        }
    }, [selectedCategoryId]);

    const getSubcategory = (subcategoryId: string) => {
        return categories.flatMap(e => e.subCategories).find(e => e.id == subcategoryId);
    }

    const getCity = (cityId: string) => {
        return states.flatMap(e => e.cities).find(e => e.id == cityId);
    }

    const handleSubmit = async (e: FormType) => {
        if (props.withImages && attachments.length == 0) {
            setError('You need to selected at least one image');
            return;
        }
        if (!selectedState || !selectedCity) {
            setError('The province and city must be specified');
            return;
        }
        if (!selectedCategoryId || !selectedSubcategory) {
            setError('The category and subcategory must be specified');
            return;
        }
        const requiredAttr = selectedSubcategory.attributes.filter(e => e.isRequired);
        if (requiredAttr.length > 0) {
            const notSpeficiedAttr = requiredAttr.filter(e => !attributes.find(a => a.attributeId == e.id));
            if (notSpeficiedAttr.length > 0) {
                setError(`${notSpeficiedAttr[0].name} must be specified`);
                return;
            }
        }

        const imagesBase64: string[] = [];
        for (const file of attachments) {
            const base64 = await toBase64(file);
            imagesBase64.push(base64 + '')
        }
        const model: PostCreateModel = {
            title: e.title,
            description: e.description,
            price: e.price,
            subCategoryId: selectedSubcategory.id,
            isActive: e.active,
            cityId: selectedCity.id,
            images: imagesBase64,
            subcategoriesAttributes: attributes
        }

        props.onSubmit(model);
    }

    const getSubcategories = () => {
        if (!selectedCategoryId) return [];
        const category = categories.find(e => e.id == selectedCategoryId);
        if (!category) return [];
        return category.subCategories;
    }

    const handleChangeSubcategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (!e.target.value || !selectedCategoryId) return;
        const category = categories.find(c => c.id == selectedCategoryId);
        if (!category) return;

        setAttributes([]);
        const subcategory = category.subCategories.find(s => s.id == e.target.value);
        setSelectedSubcategory(subcategory);
    }

    const handleSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !e.target.files.length) return;
        const file = e.target.files[0];
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg', 'image/webp'];
        if (!validImageTypes.includes(file['type'])) {
            // invalid file type code goes here.
            setError('We currenly only accept images (jpeg, gif, webp and png)')
            return;
        }
        var filesize = Number(((file.size / 1024) / 1024).toFixed(4)); // MB
        if (filesize > 5) {
            setError('The file cannot be larger than 5 MB')
            return;
        }
        if (attachments.length > 4) {
            setError('You can only attached 5 images maximun in a single message.');
            return;
        }
        setAttachements([...attachments, file]);
    }

    const handleRemoveImage = (file: File) => {
        setAttachements(attachments.filter(e => e != file))
    }

    return (
        <div
            style={{
                //display: 'flex',
                //flexDirection: 'row',
                //justifyContent: 'center',

                marginTop: 15
            }}
        >
            <ErrorModal
                message={error}
                onClose={() => setError('')}
            />
            <Box
                borderWidth={1}
                style={{
                    padding: 10,
                    maxWidth: 700,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={FormSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => (
                        <Stack spacing={4}>

                            <Heading
                                size='sm'
                            >
                                Category *
                            </Heading>

                            <Select
                                placeholder="--- Select category ----"
                                value={selectedCategoryId}
                                onChange={e => setSelectedCategoryId(e.target.value)}
                            >
                                {categories.map(e => (
                                    <option
                                        key={e.id}
                                        value={e.id}
                                    >
                                        {e.name}
                                    </option>
                                ))}
                            </Select>

                            <Heading
                                size='sm'
                            >
                                Subcategory *
                            </Heading>

                            <Select
                                placeholder="--- Select subcategory ----"
                                value={selectedSubcategory ? selectedSubcategory.id : ''}
                                onChange={handleChangeSubcategory}
                            >
                                {getSubcategories().map(e => (
                                    <option
                                        key={e.id}
                                        value={e.id}
                                    >
                                        {e.name}
                                    </option>
                                ))}
                            </Select>

                            <hr />

                            {props.withImages && (
                                <>
                                    <Heading
                                        size='sm'
                                    >
                                        Images
                                    </Heading>

                                    <PostFormImageSection
                                        attachments={attachments}
                                        onSelectImage={handleSelectImage}
                                        onRemoveAttachments={handleRemoveImage}
                                    />

                                    <hr />
                                </>
                            )}

                            <Heading
                                size='sm'
                            >
                                Name
                            </Heading>
                            <FormInput
                                bg='white'
                                value={values.title}
                                error={errors.title}
                                placeholder='Ad title'
                                onValueChanged={(e) => setFieldValue('title', e)}
                            />
                            <Heading
                                size='sm'
                            >
                                Price
                            </Heading>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    color="gray.300"
                                    fontSize="1.2em"
                                    children="$"
                                />
                                <Input
                                    placeholder="Price"
                                    value={values.price}
                                    error={errors.price}
                                    type='number'
                                    min={1}
                                    onChange={(e) => setFieldValue('price', e.target.value)}
                                />
                            </InputGroup>
                            {errors.price && (
                                <Text
                                    style={{
                                        color: 'red'
                                    }}
                                >
                                    {errors.price}
                                </Text>
                            )}
                            <Heading
                                size='sm'
                            >
                                Description
                            </Heading>
                            <FormTextArea
                                bg={'white'}
                                value={values.description}
                                error={errors.description}
                                placeholder='Description'
                                onValueChanged={(e) => setFieldValue('description', e)}
                            />
                            <Checkbox
                                defaultIsChecked
                                checked={values.active}
                                onChange={() => setFieldValue('active', !values.active)}
                            >
                                Active
                            </Checkbox>

                            <PostFormAttributeSection
                                attributes={attributes}
                                subcategory={selectedSubcategory}
                                onAttributesChanged={setAttributes}
                            />

                            <PostFormLocationSection
                                states={states}
                                selectedState={selectedState}
                                selectedCity={selectedCity}
                                onStateChanged={setSelectedState}
                                onCityChanged={setSelectedCity}
                            />

                            <Button
                                fontFamily={'heading'}
                                mt={8}
                                w={'full'}
                                bgGradient="linear(to-r, red.400,pink.400)"
                                color={'white'}
                                _hover={{
                                    bgGradient: 'linear(to-r, red.400,pink.400)',
                                    boxShadow: 'xl',
                                }}
                                disabled={props.loading}
                                isLoading={props.loading}
                                onClick={() => handleSubmit()}
                            >
                                Publish
                            </Button>
                        </Stack>
                    )}
                </Formik>

            </Box>
        </div>
    )
};
export default PostForm;
