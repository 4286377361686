import { createStylesheet } from "src/utils/style";
import { Box, Text } from "@chakra-ui/react"

const AboutUsPage = () => {

    return (
        <Box bgGradient='linear(to-l, #444, #4299E1)'>
            <div style={styles.root}>
                <Text fontSize='32' fontWeight='bold' color='#fff'>
                    About us
                </Text>
                <Text style={{ marginTop: 20, color: '#fff' }}>
                    Sangano is an online platform that brings together property owners both residential, commercial and land leasing out property to prospective tenants as well as, sellers and buyers of new and second hand items. To access the site internet connection is required. The platform allows the property seeker to go through various options according to their specifications Sangano was designed to cut down the time involved in securing a property for those who seek it and to provide sellers a wide market of the goods they are selling. The existence of the third party agents is therefore cut since there is direct.
                </Text>
            </div>
        </Box>
    )
};
export default AboutUsPage;
const styles = createStylesheet({
    root: {
        padding: 30,
        paddingBottom: 100,
    }
})