import { Heading, Tab, Table, TabList, Text, TabPanel, TabPanels, Tabs, Tbody, Td, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ChatModel } from "src/models/chat.model";
import authStore from "src/store/auth.store";
import post from "src/store/post";
import uiStore from "src/store/ui.store";
import { createStylesheet } from "src/utils/style";
import useWindowDimensions from "src/window.dimension";
import ChatListCard from "./chat.list.card";

type Props = {
    screenSize?: 'desktop' | 'mobile';
    onSelectChat: (chat: ChatModel) => void;
}

const ChatList = (props: Props) => {

    const { onSelectChat, screenSize = 'desktop' } = props;

    ///const chats = [];

    const [chats, setChats] = useState<any[]>([])

    const { height } = useWindowDimensions();
    const { headerHeight } = uiStore;

    useEffect(() => {

        for (var i = 0; i < 20; i++) {
            chats.push({
                post: {
                    title: `${Math.floor(Math.random() * 120)} gb ram`,
                    images: [
                        {
                            id: '1',
                            url: imgs[Math.floor(Math.random() * imgs.length)]
                        }
                    ],
                    user: {
                        firstName: 'alex',
                        lastName: 'kass longo'
                    },
                    price: 500
                },
                user: {
                    firstName: `alex ${Math.floor(Math.random() * 100)}`,
                    lastName: 'kass dollar 87 kimo sa dams san uoi 23 jsiu'
                },
                attachments: [
                    {
                        url: imgs[0]
                    },
                    {
                        url: imgs[1]
                    },
                    {
                        url: imgs[0]
                    }
                ],
                lastMessage: {
                    message: 'let me know tomorrow',
                    dateSent: '30/08/2021'
                }
            })
        }


    }, []);

    const getHeight = () => {
        return screenSize == 'desktop' ? height - 200 : '80vh';
    }

    const imgs = [
        'https://images.unsplash.com/photo-1619953942547-233eab5a70d6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=358&q=80',
        'https://images.unsplash.com/photo-1619953983867-eb8426ab271e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=358&q=80',
        'https://images.unsplash.com/photo-1578353022142-09264fd64295?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=358&q=80',
        'https://images.unsplash.com/photo-1485965120184-e220f721d03e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80',
        'https://images.unsplash.com/photo-1511919884226-fd3cad34687c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
    ]

    return (
        <div style={styles.root}>
            <Tabs defaultIndex={1} borderBottomColor="transparent">
                <TabList>
                    <Tab py={4} m={0} _focus={{ boxShadow: "none" }}>
                        Buying
                    </Tab>
                    <Tab py={4} m={0} _focus={{ boxShadow: "none" }}>
                        Selling
                    </Tab>
                </TabList>

                <TabPanels>
                    <div
                        style={{
                            ...styles.content, height: getHeight()
                        }}
                    >
                        {chats.map((e, i) => (
                            <div
                                //@ts-ignore
                                onClick={() => onSelectChat(e)}
                            >
                                <ChatListCard
                                    //@ts-ignore
                                    chat={e}
                                    selected={i == 3}
                                />
                                <hr />
                            </div>
                        ))}
                    </div>

                </TabPanels>
            </Tabs>


        </div>
    )

};
export default ChatList;
const styles = createStylesheet({
    root: {
        // height: '100vh',
    },
    header: {
        padding: 10,
        //background: '#EBEEEF'
    },
    content: {
        overflowY: 'scroll',
        paddingBottom: '50px'
    }
})