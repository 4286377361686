import { makeAutoObservable } from "mobx";

class UiStore {

    private _selectedStateId?: string;
    private _selectedCityId?: string;
    private _showFooter = true;
    private _headerHeight = 0;
    private _hideHeader = false;
    private _headerSticky = true;

    constructor() {
        makeAutoObservable(this);
    }

    get hideHeader() {
        return this._hideHeader;
    }

    get selectedStateId() {
        return this._selectedStateId;
    }

    get selectedCityId() {
        return this._selectedCityId;
    }

    get showFooter() {
        return this._showFooter;
    }

    get headerHeight() {
        return this._headerHeight;
    }

    get headerSticky() {
        return this._headerSticky;
    }

    setShowFooter(value: boolean) {
        this._showFooter = value;
    }

    setSelectedStateId(id: string) {
        this._selectedStateId = id;
    }

    setSelectedCity(id: string) {
        this._selectedCityId = id;
    }

    setHeaderHeight(value: number) {
        this._headerHeight = value;
    }

    setHideHeader(value: boolean) {
        this._hideHeader = value;
    }

    setHeaderSticky(value: boolean) {
        this._headerSticky = value;
    }
};
export default new UiStore();
