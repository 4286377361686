import { useState } from "react";
import { ChatModel } from "src/models/chat.model";
import { createStylesheet } from "src/utils/style";
import Chat from "./chat"
import ChatList from "./chat.list"

const ChatDesktop = () => {

    const [selectedChat, setSelectedChat] = useState<ChatModel>();

    const handleSelectChat = (e: ChatModel) => {
        setSelectedChat(undefined);
        setSelectedChat(e);
    }

    return (
        <div
            style={styles.root}
        >
            <div style={styles.left}>
                <ChatList onSelectChat={handleSelectChat} />
            </div>
            <div style={styles.right}>
                {selectedChat ? (
                    <Chat chat={selectedChat} />
                ) : (
                    <></>
                )}
            </div>
        </div>
    )

};
export default ChatDesktop;

const styles = createStylesheet({
    root: {
        display: 'flex', 
        flexDirection: 'row'
    },
    left: {
        width: '35%'
    },
    right: {
        width: '100%'
    }
});

