import {
    Box,
    chakra,
    Container,
    SimpleGrid,
    Stack,
    Text,
    VisuallyHidden,
    useColorModeValue,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AppLogo from './app.logo';

const SocialButton = ({
    children,
    label,
    href,
    target
}: {
    children: ReactNode;
    label: string;
    href: string;
    target?: string;
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            target={target}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

const ListHeader = ({ children }: { children: ReactNode }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    );
};

export default function LargeWithNewsletter() {
    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}>
            <Container as={Stack} maxW={'6xl'} py={10}>
                <SimpleGrid
                    templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
                    spacing={8}>
                    <Stack spacing={6}>
                        <Box>
                            <AppLogo />
                        </Box>
                        <Text fontSize={'sm'}>
                            © 2022 Sangano
                        </Text>
                        <Stack direction={'row'} spacing={6}>
                            <SocialButton label={'Facebook'} target='_blank' href={'https://www.facebook.com/Sangano.Online'}>
                                <FaFacebook />
                            </SocialButton>
                            <SocialButton label={'Instagram'} target='_blank' href={'https://www.instagram.com/sangano.onlinestore/'}>
                                <FaInstagram />
                            </SocialButton>
                        </Stack>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Company</ListHeader>
                        <Link to={'/about_us'}>About us</Link>
                        <Link to='#'>Blog</Link>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    );
}