import { observer } from "mobx-react";
import { matchPath, RouteComponentProps } from "react-router";
import PageNotFound from "src/components/page.notfound";
import categoryStore from "src/store/category.store";
import { createStylesheet } from "src/utils/style";
import { Heading, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from "@chakra-ui/react"
import SubcategoryCard from "src/components/categories/subcategory.card";
import SubCategory from "src/store/subcategory";
import { appStyles } from "src/utils/app.styles";
import { stopAnimation } from "framer-motion/types/render/utils/animation";

interface Props extends RouteComponentProps<any> {

}

const CategoryPage = observer((props: Props) => {

    const match = matchPath<{ id: string }>(props.history.location.pathname, {
        path: "/categories/:id",
        exact: true,
        strict: true
    });
    const categoryId = match!.params["id"];

    const category = categoryStore.getById(categoryId);

    const handleGoHome = () => {
        props.history.push("/")
    }

    const handleClick = (subcategory: SubCategory) => {
        props.history.push(`/posts/${subcategory.id}`)
    }

    return (
        <div
            style={{
                padding: 10
            }}
        >
            {category ? (
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <BreadcrumbLink
                                href="#"
                                onClick={handleGoHome}
                            >
                                Home
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <BreadcrumbLink href="#">
                                {category?.name}
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Heading
                        size="lg"
                        style={{ marginTop: 10 }}
                    >
                        Browse By Subcategory
                    </Heading>
                    <div
                        style={{
                            marginTop: 15
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "center"
                            }}
                        >
                            {category?.subCategories.map(e => (
                                <Box
                                    style={{
                                        marginLeft: 10,
                                        marginTop: 10
                                    }}
                                >
                                    <SubcategoryCard
                                        subcategory={e}
                                        onClick={handleClick}
                                    />
                                </Box>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <PageNotFound {...props} />
                </div>
            )}
        </div>
    )
});
export default CategoryPage;
const styles = createStylesheet({
    root: {

    },
    img: {
        height: 300,
        width: "100%"
    },
    headerDiv: {
        padding: 20
    },
    searchDiv: {

    }
})