import { AiOutlineClose } from "react-icons/ai";
import { appStyles } from "src/utils/app.styles";
import { Text } from '@chakra-ui/react';

type Props = {
    file: File,
    onRemove: (file: File) => void;
}

const ChatAttachmentSend = (props: Props) => {

    const { file } = props;

    const handleRemove = () => {
        props.onRemove(file);
    }

    return (
        <div
            style={{
                ...appStyles.rowSpaceBetween, padding: 10
            }}
        >
            <div style={{ ...appStyles.rowCenter, maxWidth: '90%' }}>
                <img
                    src={URL.createObjectURL(file)}
                    style={{
                        width: 30,
                        height: 30,
                        objectFit: 'cover'
                    }}
                />
                <Text
                    style={{ marginLeft: 10 }}
                    className='ellipse'
                >
                    {file.name}
                </Text>
            </div>

            <AiOutlineClose
                size={24}
                onClick={handleRemove}
            />

        </div>
    )

};
export default ChatAttachmentSend;
