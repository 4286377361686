import { Heading, Select } from "@chakra-ui/react";
import { CityModel, StateModel } from "src/models/state.model";
import State from "src/store/state";

type Props = {
    states: State[],
    selectedState?: State,
    selectedCity?: CityModel,
    onStateChanged: (state?: State) => void;
    onCityChanged: (city?: CityModel) => void;
}

const PostFormLocationSection = (props: Props) => {

    const { states, selectedState, selectedCity } = props;

    const handleSelectState = (id: string) => {
        props.onStateChanged(id ? states.find(e => e.id == id) : undefined);
        props.onCityChanged(undefined);
    }

    const handleSelectCity = (id: string) => {
        props.onCityChanged(id ? selectedState?.cities.find(e => e.id == id) : undefined);
    }

    const getCities = () => {
        return selectedState ? selectedState.cities : [];
    }

    return (
        <div>
            <Heading
                size='sm'
                style={{ marginTop: 15 }}
            >
                State *
            </Heading>

            <Select
                placeholder="--- Select province ----"
                value={selectedState ? selectedState.id : ''}
                onChange={e => handleSelectState(e.target.value)}
                style={{ marginTop: 15 }}
            >
                {states.map(e => (
                    <option
                        key={e.id}
                        value={e.id}
                    >
                        {e.name}
                    </option>
                ))}
            </Select>

            <Heading
                size='sm'
                style={{ marginTop: 15 }}
            >
                City *
            </Heading>

            <Select
                placeholder="--- Select city ----"
                onChange={e => handleSelectCity(e.target.value)}
                value={selectedCity ? selectedCity.id : ''}
                style={{ marginTop: 15 }}
            >
                {getCities().map(e => (
                    <option
                        key={e.id}
                        value={e.id}
                    >
                        {e.name}
                    </option>
                ))}
            </Select>
        </div>
    )

};
export default PostFormLocationSection;
