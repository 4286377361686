import { PropsWithChildren } from "react";
import { RouteComponentProps } from "react-router";
import PageNotFound from "./page.notfound";

interface Props extends PropsWithChildren<{}>, RouteComponentProps<any> {
    loading: boolean,
    value?: any,
}

const FetchDataView = (props: Props) => {

    return (
        <div>
            {props.loading ? (
                <div>
                    Loading....
                </div>
            ) : (
                props.value ? (
                    props.children
                ) : (
                    <PageNotFound {...props} />
                )
            )}
        </div>

    )

};
export default FetchDataView;
