import { Alert, AlertIcon, AlertDescription } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Box, Heading, Stack } from "@chakra-ui/layout";
import { Checkbox, Input, InputGroup, InputLeftElement, Text, Select } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { Formik } from "formik";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { matchPath, RouteComponentProps, RouterProps } from "react-router-dom";
import ErrorModal from "src/components/error.modal";
import FormInput from "src/components/form/form.input";
import FormTextArea from "src/components/form/form.textarea";
import HorizontalScroll from "src/components/horizontal.scroll";
import PageNotFound from "src/components/page.notfound";
import PostForm from "src/components/posts/form/post.form";
import PostFormAttributeSection from "src/components/posts/form/post.form.attribute.section";
import PostFormImageSection from "src/components/posts/form/post.form.image.section";
import PostFormLocationSection from "src/components/posts/form/post.form.location.section";
import { CategoryModel, SubCategoryAttributeCreateModel, SubCategoryModel } from "src/models/category.model";
import { PostCreateModel, PostModel } from "src/models/post.model";
import { CityModel, StateModel } from "src/models/state.model";
import authStore from "src/store/auth.store";
import categoryStore from "src/store/category.store";
import postStore from "src/store/post.store";
import State from "src/store/state";
import stateStore from "src/store/state.store";
import subcategoryStore from "src/store/subcategory.store";
import uiStore from "src/store/ui.store";
import { createStylesheet } from "src/utils/style";
import { toBase64 } from "src/utils/util";
import * as yup from 'yup';
import { AiOutlineDelete } from 'react-icons/ai'

type Props = RouteComponentProps<any> & {}

type TabModelType = 'update' | 'pictures'

type TabModel = {
    title: string,
    type: TabModelType
}


const PostFormUpdatePage = observer((props: Props) => {

    const match = matchPath<{ id: string }>(props.history.location.pathname, {
        path: "/posts/:id/update",
        exact: true,
        strict: true
    });
    const postId = match?.params.id;

    if (!postId) return <PageNotFound {...props} />;

    const post = postStore.getById(postId);
    const { user } = authStore;
    const { all: states } = stateStore;
    const { all: categories } = categoryStore;

    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState('');
    const [uploadingImage, setUploadingImage] = useState(false);
    const [files, setFiles] = useState<File[]>([]);

    const tabs: TabModel[] = [
        {
            title: `Update`,
            type: 'update'
        },
        {
            title: `Pictures`,
            type: 'pictures'
        }
    ];

    const [selectedTab, setSelectedTab] = useState<TabModelType>('update');

    useEffect(() => {

        uiStore.setHeaderSticky(false);

        if (!post) {
            fetchPost();
            return;
        }

        return () => {
            uiStore.setHeaderSticky(true);
        }


    }, []);

    const fetchPost = () => {
        if (loading) return;
        setLoading(true);
        postStore.fetchById(postId)
            .finally(() => setLoading(false))
    }

    const handleSubmit = (model: PostCreateModel) => {
        if (updating) return;
        setUpdating(true);
        postStore.update(postId, model)
            .then(() => {
                props.history.goBack();
            })
            .finally(() => setUpdating(false));
    }

    const handleRemoveImage = (imageId: string) => {
        if (!post) return;
        if (post.images.length > 1) {
            postStore.deleteImage(postId, imageId)
        }
        else {
            setError('An ad must have at least one picture');
        }
    }

    const handleSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !e.target.files.length) return;
        const file = e.target.files[0];
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg', 'image/webp'];
        if (!validImageTypes.includes(file['type'])) {
            // invalid file type code goes here.
            setError('We currenly only accept images (jpeg, gif, webp and png)')
            return;
        }
        var filesize = Number(((file.size / 1024) / 1024).toFixed(4)); // MB
        if (filesize > 5) {
            setError('The file cannot be larger than 5 MB')
            return;
        }
        setFiles([file]);
    }

    const handleUploadImage = async () => {
        if (files.length == 0 || uploadingImage) return;
        setUploadingImage(true)
        const base64 = await toBase64(files[0]);
        postStore.uploadImage(postId, base64 + '')
            .then(() => setFiles([]))
            .catch(() => setError('We failed your picture, please try again.'))
            .finally(() => setUploadingImage(false));
    }

    return (
        <>
            <ErrorModal
                message={error}
                onClose={() => setError('')}
            />
            <div
                style={{
                    position: 'sticky',
                    background: '#fff',
                    top: 0,
                    zIndex: 1000
                }}
            >
                <HorizontalScroll>
                    {tabs.map(e => (
                        <Box
                            key={e.title}
                            style={styles.tab}
                            rounded='lg'
                            shadow='md'
                            background={e.type == selectedTab ? 'blue.400' : 'white'}
                            color={e.type == selectedTab ? 'white' : 'black'}
                            onClick={() => setSelectedTab(e.type)}
                        >
                            {e.title}
                        </Box>
                    ))}
                </HorizontalScroll>
            </div>

            {loading ? (
                <>
                    Loading...
                </>
            ) : (
                post && user && post.user.id == user.id ? (
                    <>
                        <div
                            style={{
                                display: selectedTab == 'update' ? 'block' : 'none'
                            }}
                        >
                            <PostForm
                                states={states}
                                loading={updating}
                                withImages={false}
                                initialPost={post}
                                categories={categories}
                                onSubmit={handleSubmit}
                            />
                        </div>
                        <div
                            style={{
                                display: selectedTab == 'pictures' ? 'block' : 'none',
                                padding: 10
                            }}
                        >

                            <Box
                                style={{
                                    padding: 10
                                }}
                                borderWidth={1}
                            >

                                <PostFormImageSection
                                    attachments={files}
                                    onRemoveAttachments={() => setFiles([])}
                                    onSelectImage={handleSelectImage}
                                />

                                <Button
                                    colorScheme="blue"
                                    disabled={uploadingImage || files.length == 0}
                                    isLoading={uploadingImage}
                                    onClick={handleUploadImage}
                                    style={{
                                        marginTop: 15
                                    }}
                                >
                                    Upload
                                </Button>
                            </Box>


                            <div style={{ marginTop: 50 }}>
                                {post.images.map(e => (
                                    <>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                //alignItems: 'center',
                                                justifyContent: 'space-between',
                                                marginTop: 10
                                            }}
                                        >
                                            <img
                                                src={e.url}
                                                style={{
                                                    width: 120,
                                                    height: 120,
                                                    objectFit: 'cover'
                                                }}
                                            />

                                            <AiOutlineDelete
                                                style={{
                                                    cursor: 'pointer',
                                                    fontSize: 32
                                                }}
                                                onClick={() => handleRemoveImage(e.id)}
                                            />
                                        </div>
                                        <hr style={{ marginTop: 10 }} />
                                    </>
                                ))}
                            </div>
                        </div>
                    </>
                ) : (
                    <PageNotFound {...props} />
                )
            )}
        </>
    )
});
export default PostFormUpdatePage;
const styles = createStylesheet({
    root: {

    },
    tab: {
        padding: 10,
        marginLeft: 10,
        cursor: 'pointer'
    }
});
