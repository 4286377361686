import { action, makeAutoObservable, observable } from "mobx";
import { UserModel, UserProfileModel, UserUpdateModel, UpdatePasswordModel, SignupModel } from "../models/user.model";
import AuthService from "../services/auth.service";
import * as signalR from "@microsoft/signalr";
import chatStore from "./chat.store";


class AuthStore {

    private connection: signalR.HubConnection;
    @observable private _user: UserProfileModel | undefined = undefined;

    constructor() {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl("https://localhost:5001/chatHub")
            .configureLogging(signalR.LogLevel.Information)
            .build();

        makeAutoObservable(this)
    }

    get user() {
        return this._user;
    }

    increaseActivesPosts() {
        if (!this._user) return;
        this._user.activeAds += 1;
    }

    increaseInactivePosts() {
        if (!this._user) return;
        this._user.inactiveAds += 1;
    }

    @action setAuth(model: UserProfileModel) {
        this._user =  model;
        this.connection.start()
            .then(() => {
                console.log("connected to the hub")
            })
            .catch(() => {
                console.log("failed to connect...")
            });
        this.connection.on("NewMessage", (chatId: string) => {
            //catalogStore.clear();
            if (chatStore.currentChat && chatStore.currentChat.id == chatId) {
                chatStore.loadUnreadMessages();
            }
            else {
                this.fetchUnreadMessage();
                /*message.warning({
                    content: "You have a new message",
                    key: "new_message",
                    duration: 7
                });*/
                if (chatStore.chats.length > 0) {
                    // the user is probably on the page list.
                    chatStore.fetchChat(chatId);
                }
            }
        });
    }

    @action async auth(username: string, password: string) {
        const res = await AuthService.auth(username, password);
        if (res.data) {
            this.setAuth(res.data);
        }
    }

    @action async signUp(model: SignupModel) {
        const res = await AuthService.signUp(model);
        if (res.data) {
            this.setAuth(res.data);
        }
    }

    @action async fetchProfile() {
        const res = await AuthService.loadProfile();
        if (res.data) {
            this.setAuth(res.data);
        }
    }

    @action async fetchUnreadMessage() {
        const res = await AuthService.countUnreadMessage();
        if (this._user) {
            this._user.unreadMessages = res.data;
        }
        //alert("fecth un read message : " + res.data)
    }

    @action async update(model: UserUpdateModel) {
        const res = await AuthService.update(model);
        if (res.data) {
            this._user = res.data;
        }
    }

    @action async removeProfile() {
        if (!this._user) return;
        await AuthService.removeProfile();
        this._user.profile = "";
    }

    @action async setProfilePic(data: string) {
        const res = await AuthService.setProfilePic(data);
        if (res.data && this._user) {
            this._user.profile = res.data.url;
        }
    }

    @action async updatePassword(model: UpdatePasswordModel) {
        await AuthService.updatePassword(model);
    }

    @action async logout() {
        await AuthService.logout();
        this._user = undefined;
    }

};
export default new AuthStore();
