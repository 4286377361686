import { createStylesheet } from "./style";

export const appStyles = createStylesheet({
    ellipse: {
        display: "-webkit-box",
        textOverflow: "ellipsis",
        overflow: "hidden",
        textTransform: "capitalize",
        whiteSpace: "nowrap",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        //whiteSpace: "nowrap"
    },
    twoLines: {
        WebkitLineClamp: 2,
    },
    threeLines: {
        WebkitLineClamp: 3
    },
    fourLines: {
        WebkitLineClamp: 4,
    },
    rowCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    rowSpaceBetween: {
        display: 'flex',
        justifyContent: 'space-between'
    }
});
