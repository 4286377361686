import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel";
import Carousel from "react-multi-carousel";
import Category from "src/store/category";
import "react-multi-carousel/lib/styles.css";
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Button, Heading } from "@chakra-ui/react";
import AppCarousel from "../app.carousel";


type Props = {
    categories: Category[],
    onClick: (category: Category) => void;
}

const CategoryCarousel = (props: Props) => {

    const { categories } = props;

    return (
        <div style={{ padding: 20 }}>
            <Heading size="lg">
                Browse by categories
            </Heading>
            <div
                style={{
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    marginTop: 10,
                    justifyContent: 'center'
                }}

            >
                {categories.map(e => (
                    <div
                        style={{ marginLeft: 10, marginTop: 20 }}
                        onClick={() => props.onClick(e)}
                    >
                        <img
                            src={e.images[0]}
                            alt=""
                            style={{
                                width: 150,
                                height: 120
                            }}
                        />
                        <h2
                          style={{ marginTop: 5, textDecoration: 'underline', cursor: 'pointer' }}
                        >
                            {e.name}
                        </h2>
                    </div>
                ))}
            </div>
        </div>
    )
};
export default CategoryCarousel;
