import { Heading, Box } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import AuthenticatedView from "src/components/authenticated.view";
import ErrorModal from "src/components/error.modal";
import HorizontalScroll from "src/components/horizontal.scroll";
import ProfileImageUpload from "src/components/profile/profile.image.upload";
import ProfileUpdateForm from "src/components/profile/profile.update.form";
import ProfileUpdatePassword from "src/components/profile/profile.update.password";
import { UpdatePasswordModel, UserUpdateModel } from "src/models/user.model";
import authStore from "src/store/auth.store";
import uiStore from "src/store/ui.store";
import { createStylesheet } from "src/utils/style";


type Props = RouteComponentProps & {}

type TabModelType = 'edit-profile' | 'edit-profile-picture' | 'change-password'

type TabModel = {
    title: string,
    type: TabModelType
}

const tabs: TabModel[] = [
    {
        title: `Edit profile`,
        type: 'edit-profile'
    },
    {
        title: `Change profile picture`,
        type: 'edit-profile-picture'
    },
    {
        title: 'Change password',
        type: 'change-password'
    }
];

const ProfilePage = observer((props: Props) => {

    const { user } = authStore;
    const [selectedTab, setSelectedTab] = useState<TabModelType>('edit-profile');

    const [error, setError] = useState('');
    const [updatingProfile, setUpdatingProfile] = useState(false);
    const [uploadingPicture, setUploadingPicture] = useState(false);
    const [updatingPwd, setUpdatingPwd] = useState(false);

    useEffect(() => {

        uiStore.setHeaderSticky(false);

        return () => {
            uiStore.setHeaderSticky(true);
        }

    }, []);

    const handleUpdateProfile = (e: UserUpdateModel) => {
        if (updatingProfile) return;
        setUpdatingProfile(true);
        authStore.update(e)
            .then(() => props.history.goBack())
            .catch(() => setError('We failed to update your profile, please try again.'))
            .finally(() => setUpdatingProfile(false))
    }

    const handleUploadPicture = (e: string) => {
        if (uploadingPicture) return;
        setUploadingPicture(true);
        authStore.setProfilePic(e)
            .then(() => props.history.goBack())
            .catch(() => setError('We failed to update your profile picture, please try again'))
            .finally(() => setUploadingPicture(false))
    }

    const handleUpdatePassword = (model: UpdatePasswordModel) => {
        if (updatingPwd) return;
        setUpdatingPwd(true);
        authStore.updatePassword(model)
            .then(() => props.history.goBack())
            .catch((e: AxiosError<{ message: string }>) => {
                if (e.response) {
                    setError(e.response?.data.message)
                }
                else {
                    setError('Something went wrong please try again.')
                }
            })
            .finally(() => setUpdatingPwd(false));
    }

    return (
        <AuthenticatedView
            user={user}
            {...props}
        >
            <>


                <ErrorModal
                    message={error}
                    onClose={() => setError('')}
                />

                <div
                    style={{
                        position: 'sticky',
                        background: '#fff',
                        top: 0,
                        zIndex: 1000
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: 10
                        }}
                    >
                        <Heading size='lg'>
                            My profile
                        </Heading>

                        <div>

                        </div>
                    </div>

                    <HorizontalScroll>
                        {tabs.map(e => (
                            <Box
                                key={e.title}
                                style={styles.tab}
                                rounded='lg'
                                shadow='md'
                                background={e.type == selectedTab ? 'blue.400' : 'white'}
                                color={e.type == selectedTab ? 'white' : 'black'}
                                onClick={() => setSelectedTab(e.type)}
                            >
                                {e.title}
                            </Box>
                        ))}
                    </HorizontalScroll>

                    {user && (
                        <>
                            <div
                                style={{
                                    display: selectedTab == 'edit-profile' ? 'block' : 'none'
                                }}
                            >
                                <ProfileUpdateForm
                                    user={user}
                                    loading={updatingProfile}
                                    onSubmit={handleUpdateProfile}
                                />
                            </div>

                            <div
                                style={{
                                    display: selectedTab == 'edit-profile-picture' ? 'block' : 'none'
                                }}
                            >
                                <ProfileImageUpload
                                    user={user}
                                    loading={uploadingPicture}
                                    onUploadProfilePic={handleUploadPicture}
                                />
                            </div>

                            <div
                                style={{
                                    display: selectedTab == 'change-password' ? 'block' : 'none'
                                }}
                            >
                                <ProfileUpdatePassword
                                    loading={updatingPwd}
                                    onSubmit={handleUpdatePassword}
                                />
                            </div>
                        </>
                    )}
                </div>

            </>
        </AuthenticatedView>
    )

});
export default ProfilePage;
const styles = createStylesheet({
    root: {

    },
    tab: {
        padding: 10,
        marginLeft: 10,
        cursor: 'pointer'
    }
});
