import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { ChatModel } from "src/models/chat.model";
import uiStore from "src/store/ui.store";
import MobileHeader from "../mobile.header";
import Chat from "./chat";
import ChatList from "./chat.list";

type Props = {
    router: RouteComponentProps
}


const ChatMobile = (props: Props) => {

    const [selectedChat, setSelectedChat] = useState<ChatModel>();

    useEffect(() => {

        uiStore.setHideHeader(true);

        return () => {
            uiStore.setHideHeader(false);
        }

    }, []);

    return (
        <div>
            {selectedChat ? (
                <Chat
                    chat={selectedChat}
                    screenSize='mobile'
                    onBackClick={() => setSelectedChat(undefined)}
                />
            ) : (
                <>
                    <MobileHeader
                       title='Chats'
                       onGoBack={props.router.history.goBack}
                    />
                    <ChatList
                        screenSize='mobile'
                        onSelectChat={setSelectedChat}
                    />
                </>
            )}
        </div>
    )

};
export default ChatMobile;
