import { Avatar, Box, Divider, Heading, Stack, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { matchPath, RouteComponentProps } from "react-router";
import FetchDataView from "src/components/fetch.api";
import PostList from "src/components/posts/post.list";
import { PostModel, PostSearchModel } from "src/models/post.model";
import { UserModel } from "src/models/user.model";
import apiService from "src/services/api.service";
import { formatMoney, nFormatter } from "src/services/util";
import authStore from "src/store/auth.store";
import postStore from "src/store/post.store";
import { createStylesheet } from "src/utils/style";
import useWindowDimensions from "src/window.dimension";

type Props = RouteComponentProps<any> & {}

const UserProfilePage = observer((props: Props) => {

    const match = matchPath<{ id: string }>(props.history.location.pathname, {
        path: "/users/:id",
        exact: true,
        strict: true
    });
    const userId = match?.params.id;

    const { userPosts, userHasNext } = postStore;

    const [loading, setLoading] = useState(true);
    const [postLoading, setPostLoading] = useState(false);
    const [user, setUser] = useState<UserModel>();
    const [loadingMorePosts, setLoadingMorePosts] = useState(false);

    const { width } = useWindowDimensions();

    useEffect(() => {

        fetchUser();

        return () => {

        }

    }, []);

    const fetchUser = () => {
        apiService.get<UserModel>(`users/${userId}`)
            .then((res) => {
                setUser(res.data);
                fethPosts();
            })
            .finally(() => setLoading(false))
    }

    const fethPosts = () => {
        setPostLoading(true);
        if (userPosts.userId != userId) {
            postStore.clearUserPosts();
            const searchModel: PostSearchModel = {
                userId: userId,
                limit: 15
            }
            postStore.search(searchModel, true, 'user-posts')
                .finally(() => setLoading(false))
        }
    }

    const handleLoadMorePosts = () => {
        if (loadingMorePosts) return;
        setLoadingMorePosts(true);
        //console.log('load more...')
        postStore.userNext()
            .finally(() => setLoadingMorePosts(false))
    }

    const handleClickPost = (e: PostModel) => {
        props.history.push(`/posts/${e.id}/detail`, {})
    }

    const getHeaderInfo = (): { title: string, description?: string | number }[] => {
        if (!user) return [];
        return [
            {
                title: 'Member since',
                description: moment(user.dateCreated).fromNow()
            },
            {
                title: 'Total ads',
                description: nFormatter(user.totalAds)
            },
            {
                title: 'Active ads',
                description: nFormatter(user.activeAds)
            }
        ]
    }

    const renderHeaderInfo = (index: number, title: string, description?: string | number) => {

        return (
            <div
                key={title}
                style={{
                    //marginLeft: index == 0 ? 0 : 10,
                    display: 'flex',
                    marginTop: 10,
                    width: '100%'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}
                >
                    <Text
                        size='sm'
                    >
                        {title}
                    </Text>
                    <Heading
                        size='sm'
                        className='ellipse'
                    >
                        {description}
                    </Heading>
                </div>
            </div>
        )
    }

    return (
        <div
            style={styles.root}
        >
            <FetchDataView
                loading={loading}
                value={user}
                {...props}
            >
                {user && (
                    <>
                        <Box
                            style={styles.header}
                            shadow='sm'
                        >
                            <div>
                                <Avatar
                                    src={user.profile}
                                    size='lg'
                                />
                            </div>

                            <div
                                style={{
                                    marginLeft: 10
                                }}
                            >
                                <Heading size='lg'>
                                    {user.firstName} {user.lastName}
                                </Heading>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: 250
                                    }}
                                >
                                    {getHeaderInfo().map((e, index) => (
                                        <div key={e.title}>
                                            {renderHeaderInfo(index, e.title, e.description)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Box>

                        <PostList
                            posts={userPosts.posts}
                            loading={loading}
                            loadingMore={loadingMorePosts}
                            hasNext={userHasNext}
                            onClick={handleClickPost}
                            onLoadMore={handleLoadMorePosts}
                        />
                    </>
                )}
            </FetchDataView>
        </div>
    )
})

export default UserProfilePage;
const styles = createStylesheet({
    root: {
        // padding: 10
    },
    header: {
        display: 'flex',
        // background: 'pink',
        padding: 10
    },
    headerInfo: {
        display: 'flex',
        // flexWrap: 'wrap' 
    }
});
