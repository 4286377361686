import { SubCategoryAttributeModel } from "src/models/category.model";
import SubcategoryAttributeList from "./subcategory.attribute.list";
import SubcategoryAttributeNumber from "./subcategory.attribute.number";
import {ChangeSubcategoryFilterModel} from "../posts/post.search.modal";
import { PostAttributeSearchModel } from "src/models/post.model";

type Props = {
    attribute: SubCategoryAttributeModel,
    values: PostAttributeSearchModel[],
    onChange: (value: ChangeSubcategoryFilterModel) => void;
}

const SubcategoryAttribute = (props: Props) => {

    const { attribute, onChange, values } = props;

    return (
        <div>
            {attribute.type === "YES_OR_NO" ? (
                <>
                    <SubcategoryAttributeList
                        attribute={attribute}
                        hasAny={true}
                        customValues={["Yes", "No"]}
                        onChange={onChange}
                        values={values}
                    />
                </>
            ) : (
                attribute.type == "NUMBER" ? (
                    <>
                        <SubcategoryAttributeNumber
                            attribute={attribute}
                            onChange={onChange}
                            values={values}
                        />
                    </>
                ) : (
                    attribute.type === "STRING" ? (
                        <>
                        </>
                    ) : (
                        <SubcategoryAttributeList
                            attribute={attribute}
                            hasAny={true}
                            onChange={onChange}
                            values={values}
                        />
                    )
                )
            )}
        </div>
    )

};
export default SubcategoryAttribute;
