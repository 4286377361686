import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button } from "@chakra-ui/react";
import React, { useRef } from "react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { createStylesheet } from "src/utils/style";

interface Props extends React.PropsWithChildren<any> {
    leftHeaderNode?: React.ReactNode,
    leftNode?: React.ReactNode
}

const HorizontalScroll = (props: Props) => {

    const navRef = useRef<HTMLDivElement>(null);
    const divId = (Math.random() + 1).toString(36).substring(7);

    const scrollLeft = (element: any, change: any, duration: any) => {
        var start = element.scrollLeft,
            currentTime = 0,
            increment = 20;

        //console.log(start)

        var animateScroll = function () {
            currentTime += increment;
            var val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollLeft = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    //t = current time
    //b = start value
    //c = change in value
    //d = duration
    const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };

    const handleScroll = (direction: "left" | "right") => {
        if (!navRef) return;
        const element = document.getElementById(divId)
        if (direction == "left") {
            //navRef.current?.scrollLeft -= 200;
            scrollLeft(element, -300, 1000)
        }
        else {
            //navRef.current?.scrollLeft += 200;
            scrollLeft(element, 300, 1000)
        }
    }

    return (
        <div>
          
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                {props.leftNode}
                <div
                    style={{
                        padding: 10,
                        flexDirection: "row",
                        alignItems: "center",
                        display: "flex",
                        //maxWidth: 900,
                        //flexWrap: "wrap",
                        marginTop: 5,
                        overflow: 'auto',
                        //top: 0,
                        //position: "sticky"
                    }}
                    className="custom-scroll overflow-hover"
                    id={divId}
                    ref={navRef}
                >
                    {props.children}
                </div>

            </div>
        </div>
    )

};
export default HorizontalScroll;

const styles = createStylesheet({
    topbar: {
        padding: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    btn: {
        padding: 5,
        border: "1px solid #EFEFEF",
        borderRadius: 50,
        cursor: "pointer"
    },
});