import { InputLeftElement } from "@chakra-ui/react";
import { Button, Divider, Heading, Input, InputGroup, InputLeftAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { forwardRef, useImperativeHandle, useState } from "react";
import { SubCategoryAttributeModel, SubCategoryAttributeType } from "src/models/category.model";
import { PostSearchModel, PostAttributeSearchModel } from "src/models/post.model";
import SubCategory from "src/store/subcategory";
import { createStylesheet } from "src/utils/style";
import useWindowDimensions from "src/window.dimension";
import SubcategoryAttribute from "../categories/subcategory.attribute";

export interface ChangeSubcategoryFilterModel {
    attribute: SubCategoryAttributeModel,
    value?: any,
    min?: number,
    max?: number,
    remove?: boolean
}

type Props = {
    filter: PostSearchModel,
    selectedSubcategory: SubCategory,
    values: PostAttributeSearchModel[],
    minPrice?: number;
    maxPrice?: number;
    onClear: () => void;
    onSetMinPrice: (value?: number) => void;
    onSetMaxPrice: (value?: number) => void;
    onClose: (e: PostAttributeSearchModel[]) => void;
}

export interface PostSearchModalRef {
    open: () => void;
}

const PostSearchModal = forwardRef<PostSearchModalRef, Props>((props, ref) => {

    const { filter, selectedSubcategory, minPrice, maxPrice } = props;
    const [isOpened, setOpened] = useState(false);
   // const { width } = useWindowDimensions();
    const [attributeFilter, setAttributeFilter] = useState<PostAttributeSearchModel[]>([]);

    const [localMinPrice, setLocalMinPrice] = useState<number>();
    const [localMaxPrice, setLocalMaxPrice] = useState<number>();

    useImperativeHandle<PostSearchModalRef, PostSearchModalRef>(ref, () => ({
        open: () => {
            setAttributeFilter(props.values);
            setLocalMinPrice(minPrice);
            setLocalMaxPrice(maxPrice);
            setOpened(true);
        }
    }));

    const handleClose = () => {
        props.onClose(attributeFilter);
        props.onSetMaxPrice(localMaxPrice);
        props.onSetMinPrice(localMinPrice);
        setLocalMaxPrice(undefined);
        setLocalMinPrice(undefined);
        setOpened(false);
    }

    const handleClear = () => {
        props.onClear();
        setAttributeFilter([]);
        setOpened(false);
    }

    const handleAttributeChange = (args: ChangeSubcategoryFilterModel) => {
        const { attribute, value, min, max, remove } = args;
        let copyAttr = [...attributeFilter];
        //console.clear();
        if (remove) {
            copyAttr = copyAttr.filter(e => e.subcategoryAttributeId != args.attribute.id);
        }
        else {
            const find = copyAttr.find(e => e.subcategoryAttributeId == args.attribute.id)
            if (find) {
                setAttributeValue(attribute.type, find, value, min, max);
            } else {
                const newAttr: PostAttributeSearchModel = {
                    subcategoryAttributeId: attribute.id,
                    attributeName: attribute.name,
                    type: attribute.type
                }
                setAttributeValue(attribute.type, newAttr, value, min, max);
                copyAttr.push(newAttr);
            }
        }
        setAttributeFilter(copyAttr);
        //console.log(JSON.stringify(copyAttr, null, 2));
    }

    const setAttributeValue = (type: SubCategoryAttributeType, attr: PostAttributeSearchModel, value?: any, min?: number, max?: number) => {
        if (type == "NUMBER") {
            attr.minValue = min;
            attr.maxValue = max;
        }
        else {
            attr.value = value;
        }
    }

    return (
        <>
            {isOpened && (
                <Modal
                    onClose={handleClose}
                    //finalFocusRef={btnRef}
                    isOpen={true}
                    scrollBehavior="inside"
                    size="lg"
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Filter</ModalHeader>
                        <ModalCloseButton />
                        <Divider />
                        <ModalBody>
                            <div style={{ padding: 10 }}>
                                <Heading size="md" style={{ marginTop: 15 }}>
                                    Price range
                                </Heading>
                                <div style={styles.row}>
                                    <InputGroup style={{ width: 150 }}>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            color="gray.300"
                                            fontSize="1.2em"
                                            children="$"
                                        />
                                        <Input
                                            placeholder="Min price"
                                            type="number"
                                            value={localMinPrice}
                                            onChange={e => setLocalMinPrice(Number(e.target.value))}
                                        />
                                    </InputGroup>

                                    <InputGroup style={{ marginLeft: 10, width: 150 }}>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            color="gray.300"
                                            fontSize="1.2em"
                                            children="$"
                                        />
                                        <Input
                                            placeholder="Max price"
                                            type="numner"
                                            value={localMaxPrice}
                                            onChange={e => setLocalMaxPrice(Number(e.target.value))}
                                        />
                                    </InputGroup>
                                </div>
                            </div>

                            {selectedSubcategory && (
                                selectedSubcategory.attributes.map(e => (
                                    <div style={{ marginTop: 15, padding: 10 }}>
                                        <Heading size="md" style={{ textTransform: "capitalize" }}>
                                            {e.name}
                                        </Heading>
                                        <div style={{ marginTop: 15 }}>
                                            <SubcategoryAttribute
                                                attribute={e}
                                                values={attributeFilter}
                                                onChange={handleAttributeChange}
                                            />
                                        </div>
                                    </div>
                                ))
                            )}
                        </ModalBody>

                        <ModalFooter>
                            <Button onClick={handleClear}>
                                Clear
                            </Button>
                            <Button onClick={handleClose} colorScheme="blue" style={{ marginLeft: 10 }}>
                                Done
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </>
    )
});
export default PostSearchModal;

const styles = createStylesheet({
    row: {
        //padding: 10,
        marginTop: 15,
        display: "flex",
        flexDirection: "row"
    }
});
