import { Box, Heading } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import AuthenticatedView from "src/components/authenticated.view";
import HorizontalScroll from "src/components/horizontal.scroll";
import PostList from "src/components/posts/post.list";
import { PostSearchModel } from "src/models/post.model";
import { formatMoney, nFormatter } from "src/services/util";
import authStore from "src/store/auth.store";
import postStore from "src/store/post.store";
import uiStore from "src/store/ui.store";
import { createStylesheet } from "src/utils/style";


interface Props extends RouteComponentProps { }


type TabModelType = 'active' | 'inactive' | 'favourites'

type TabModel = {
    title: string,
    type: TabModelType
}

const MyAdsPage = observer((props: Props) => {

    const { user } = authStore;
    const { userPosts, userHasNext } = postStore;

    const tabs: TabModel[] = [
        {
            title: `Active (${nFormatter(user?.activeAds)})`,
            type: 'active'
        },
        {
            title: `Inactive (${nFormatter(user?.inactiveAds)})`,
            type: 'inactive'
        },
        {
            title: 'Favourites',
            type: 'favourites'
        }
    ];

    const [loading, setLoading] = useState(false);
    const [loadingMorePosts, setLoadingMorePosts] = useState(false);
    const [selectedTab, setSelectedTab] = useState<TabModelType>('active');

    useEffect(() => {

        fetchPosts();

        uiStore.setHeaderSticky(false);

        return () => {
            uiStore.setHeaderSticky(true);
        }

    }, []);

    useEffect(() => {

        window.scrollTo(0, 0)
        fetchPosts();

    }, [selectedTab]);

    const fetchPosts = () => {

        if (!user) return;

        if (selectedTab == 'favourites') {

        }
        else {
            const model: PostSearchModel = {
                userId: user.id,
                limit: 5,
                isActive: selectedTab == 'active'
            }
            postStore.clearUserPosts();
            setLoading(true);
            postStore.search(model, true, 'user-posts')
                .finally(() => setLoading(false))
        }
    }

    const handleLoadMore = () => {
        if (loadingMorePosts) return;
        setLoadingMorePosts(true);
        postStore.userNext()
            .finally(() => setLoadingMorePosts(false));
    }


    return (
        <AuthenticatedView
            user={user}
            {...props}
        >
            <>

                <div
                    style={{
                        position: 'sticky',
                        background: '#fff',
                        top: 0,
                        zIndex: 1000
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: 10
                        }}
                    >
                        <Heading size='lg'>
                            My Ads
                        </Heading>

                        <div>

                        </div>
                    </div>

                    <HorizontalScroll>
                        {tabs.map(e => (
                            <Box
                                key={e.title}
                                style={styles.tab}
                                rounded='lg'
                                shadow='md'
                                background={e.type == selectedTab ? 'blue.400' : 'white'}
                                color={e.type == selectedTab ? 'white' : 'black'}
                                onClick={() => setSelectedTab(e.type)}
                            >
                                {e.title}
                            </Box>
                        ))}
                    </HorizontalScroll>
                </div>

                <div>
                    <PostList
                        posts={userPosts ? userPosts.posts : []}
                        loading={loading}
                        loadingMore={loadingMorePosts}
                        hasNext={userHasNext}
                        onLoadMore={handleLoadMore}
                        onClick={(e) => props.history.push(`/posts/${e.id}/detail`)}
                    />
                </div>
            </>
        </AuthenticatedView>
    )

});
export default MyAdsPage;
const styles = createStylesheet({
    root: {

    },
    tab: {
        padding: 10,
        marginLeft: 10,
        cursor: 'pointer'
    }
});
