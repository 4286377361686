import apiService from "./api.service";
import { PostModel, PostSearchModel, PostOverviewModel, PostCreateModel } from "../models/post.model";
import { Pagination } from "../models/pagination";
import { ChatModel } from "../models/chat.model";
import { AutocompleteModel } from "src/models/shared.model";


export default class PostService {

    static search(searchModel: PostSearchModel) {
        //const query = searchModel ? `?${querystring.stringify(searchModel)}` : "";
        return apiService.post<Pagination<PostModel, PostSearchModel>>(`posts/search`, {
            body: searchModel
        });
    }

    static getById(id: string) {
        return apiService.get<PostModel>(`posts/${id}`);
    }

    static getOverview() {
        return apiService.get<PostOverviewModel[]>("posts/overview")
    }

    static getRelated(postId: string) {
        return apiService.get<PostModel[]>(`posts/${postId}/related`)
    }

    static create(model: PostCreateModel) {
        return apiService.post<PostModel>("posts", {
            body: model
        });
    }

    static update(postId: string, model: PostCreateModel) {
        return apiService.put<PostModel>(`posts/${postId}`, model);
    }

    static uploadImage(postId: string, data: string) {
        return apiService.post<PostModel>(`posts/${postId}/images`, {
            body: {
                data
            }
        });
    }

    static deleteImage(postId: string, imageId: string) {
        return apiService.delete(`posts/${postId}/images/${imageId}`);
    }

    static getChat(postId: string) {
        return apiService.get<ChatModel>(`posts/${postId}/chat`);
    }

    static getFavourites() {
        return apiService.get<PostModel[]>("posts/saved_posts")
    }

    static toggleSave(postId: string) {
        return apiService.post(`posts/${postId}/toggle_save`, {})
    }

    static autocomplete(slug: string) {
        return apiService.get<AutocompleteModel[]>(`posts/autocomplete/${slug}`);
    }
}
