import { Heading, Stack } from "@chakra-ui/layout";
import { Input, Radio, RadioGroup, Select } from "@chakra-ui/react";
import { SubCategoryAttributeCreateModel, SubCategoryModel } from "src/models/category.model";

type Props = {
    attributes: SubCategoryAttributeCreateModel[],
    subcategory?: SubCategoryModel,
    onAttributesChanged: (e: SubCategoryAttributeCreateModel[]) => void;
}

const PostFormAttributeSection = (props: Props) => {

    const { subcategory, attributes, onAttributesChanged } = props;

    const getAttributeValue = (attrId: string) => {
        const attr = attributes.find(e => e.attributeId == attrId);
        if (!attr) return '';
        return attr.value;
    }

    const setAttributeValue = (attrId: string, value: string) => {
        const attr = attributes.find(e => e.attributeId == attrId);
        if (!attr) {
            const copy = attributes.concat({
                attributeId: attrId,
                value: value
            });
            onAttributesChanged(copy)
        }
        else {
            attr.value = value;
            console.log('set val=>', value)
            const copy = attributes.filter(e => e.attributeId != attrId);
            if (value) {
                onAttributesChanged(copy.concat(attr));
            }
            else {
                console.log('romove::', copy)
                onAttributesChanged(copy);
            }
        }
    }

    return (
        <div>
            {subcategory && subcategory.attributes && subcategory.attributes.length > 0 && (
                <div>
                    {subcategory.attributes.map(e => (
                        <div
                            key={e.id}
                            style={{
                                marginTop: 10
                            }}
                        >
                            <h3 style={{ textTransform: "capitalize" }}>
                                {e.name}
                            </h3>
                            {e.type == "NUMBER" ? (
                                <Input
                                    value={getAttributeValue(e.id)}
                                    type='number'
                                    onChange={v => setAttributeValue(e.id, v.target.value)}
                                    required={e.isRequired}
                                    style={{
                                        marginTop: 10
                                    }}
                                />
                            ) : (
                                e.type == "STRING" ? (
                                    <Input
                                        type="text"
                                        value={getAttributeValue(e.id)}
                                        onChange={v => setAttributeValue(e.id, v.target.value)}
                                        style={{
                                            marginTop: 10
                                        }}
                                    />
                                ) : (
                                    e.type == "YES_OR_NO" ? (
                                        <>
                                            <RadioGroup
                                                onChange={v => setAttributeValue(e.id, v)}
                                                value={getAttributeValue(e.id)}
                                                style={{
                                                    marginTop: 10
                                                }}
                                            >
                                                <Stack direction="row">
                                                    <Radio value={'true'}>Yes</Radio>
                                                    <Radio value={'false'}>No</Radio>
                                                </Stack>
                                            </RadioGroup>
                                        </>
                                    ) : (
                                        <Select
                                            id="exampleFormControlSelect1"
                                            value={getAttributeValue(e.id)}
                                            onChange={v => setAttributeValue(e.id, v.target.value)}
                                            style={{
                                                marginTop: 10
                                            }}
                                        >
                                            <option key={Math.random()} value=""></option>
                                            {e.choices && e.choices.map(c => (
                                                <option
                                                    key={c.value}
                                                    value={c.value}
                                                    style={{ textTransform: "capitalize" }}
                                                >
                                                    {c.value}
                                                </option>
                                            ))}
                                        </Select>
                                    )
                                )
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )

};
export default PostFormAttributeSection;
