import { Box, Stack, Heading, Button } from "@chakra-ui/react";
import { Formik } from "formik";
import { UpdatePasswordModel, UserUpdateModel } from "src/models/user.model";
import FormInput from "../form/form.input";
import * as yup from 'yup';
import { string } from "yup/lib/locale";

type Props = {
    loading: boolean,
    onSubmit: (model: UpdatePasswordModel) => void;
}


const fieldRequired = 'This field is required';
const FormSchema = yup.object<Record<keyof UpdatePasswordModel, yup.AnySchema>>({
    newPassword: yup.string().required(fieldRequired)
        .min(6, 'Password is too short'),
    oldPassword: yup.string().required(fieldRequired),
});

const ProfileUpdatePassword = (props: Props) => {

    const initialValues: UpdatePasswordModel = {
        newPassword: '',
        oldPassword: ''
    }


    return (
        <div>
            <Box
                borderWidth={1}
                style={{
                    padding: 10,
                    maxWidth: 700,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 20
                }}
            >
                <Heading size='md'>
                    Update password
                </Heading>
                <Formik
                    initialValues={initialValues}
                    validationSchema={FormSchema}
                    onSubmit={props.onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => (
                        <Stack spacing={4} style={{ marginTop: 15 }}>

                            <Heading size='sm'>
                                Current password*
                            </Heading>
                            <FormInput
                                bg='white'
                                value={values.oldPassword}
                                error={errors.oldPassword}
                                type='password'
                                placeholder='Current password'
                                onValueChanged={(e) => setFieldValue('oldPassword', e)}
                            />

                            <Heading size='sm'>
                                New password*
                            </Heading>
                            <FormInput
                                bg='white'
                                value={values.newPassword}
                                error={errors.newPassword}
                                placeholder='New password'
                                type='password'
                                onValueChanged={(e) => setFieldValue('newPassword', e)}
                            />

                            <Button
                                fontFamily={'heading'}
                                mt={8}
                                w={'full'}
                                bgGradient="linear(to-r, red.400,pink.400)"
                                color={'white'}
                                _hover={{
                                    bgGradient: 'linear(to-r, red.400,pink.400)',
                                    boxShadow: 'xl',
                                }}
                                disabled={props.loading}
                                isLoading={props.loading}
                                onClick={() => handleSubmit()}
                            >
                                Save changes
                            </Button>
                        </Stack>
                    )}
                </Formik>

            </Box>
        </div>
    )
};
export default ProfileUpdatePassword;
