import { Alert, AlertIcon, AlertDescription } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Box, Heading, Stack } from "@chakra-ui/layout";
import { Checkbox, Input, InputGroup, InputLeftElement, Text, Select } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { Formik } from "formik";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { RouterProps } from "react-router-dom";
import ErrorModal from "src/components/error.modal";
import FormInput from "src/components/form/form.input";
import FormTextArea from "src/components/form/form.textarea";
import PostFormAttributeSection from "src/components/posts/form/post.form.attribute.section";
import PostFormImageSection from "src/components/posts/form/post.form.image.section";
import PostFormLocationSection from "src/components/posts/form/post.form.location.section";
import { SubCategoryAttributeCreateModel, SubCategoryModel } from "src/models/category.model";
import { PostCreateModel } from "src/models/post.model";
import { CityModel } from "src/models/state.model";
import categoryStore from "src/store/category.store";
import postStore from "src/store/post.store";
import State from "src/store/state";
import stateStore from "src/store/state.store";
import subcategoryStore from "src/store/subcategory.store";
import { createStylesheet } from "src/utils/style";
import { toBase64 } from "src/utils/util";
import * as yup from 'yup';

interface Props extends RouterProps {

}

type FormType = {
    "title": string,
    "description"?: string,
    active: boolean,
    "price": number,
}

const fieldRequired = 'This field is required';
const FormSchema = yup.object<Record<keyof FormType, yup.AnySchema>>({
    title: yup.string().min(3, 'The title is too short').required(fieldRequired),
    price: yup.number().min(0, 'Invalid price').required(fieldRequired),
    description: yup.string(),
    active: yup.boolean()
});

const PostFormPage = observer((props: Props) => {

    const initialValues: FormType = {
        title: '',
        description: '',
        price: 0,
        active: true
    }

    const { all: categories } = categoryStore;
    const { all: states } = stateStore;

    const [loading, setLoading] = useState(false);
    const [selectedCategoryId, setSelectedId] = useState<string>();
    const [selectedSubcategory, setSelectedSubcategory] = useState<SubCategoryModel>();
    const [error, setError] = useState<string>('');
    const [attachments, setAttachements] = useState<File[]>([]);
    const [attributes, setAttributes] = useState<SubCategoryAttributeCreateModel[]>([]);

    const [selectedState, setSelectedState] = useState<State>();
    const [selectedCity, setSelectedCity] = useState<CityModel>();

    useEffect(() => {

        setAttributes([]);
        setSelectedSubcategory(undefined)

    }, [selectedCategoryId]);

    const handleSubmit = async (e: FormType) => {
        if (attachments.length == 0) {
            setError('You need to selected at least one image');
            return;
        }
        if (!selectedState || !selectedCity) {
            setError('The province and city must be specified');
            return;
        }
        if (!selectedCategoryId || !selectedSubcategory) {
            setError('The category and subcategory must be specified');
            return;
        }
        const requiredAttr = selectedSubcategory.attributes.filter(e => e.isRequired);
        if (requiredAttr.length > 0) {
            const notSpeficiedAttr = requiredAttr.filter(e => !attributes.find(a => a.attributeId == e.id));
            if (notSpeficiedAttr.length > 0) {
                setError(`${notSpeficiedAttr[0].name} must be specified`);
                return;
            }
        }

        if (loading) return;

        setLoading(true);

        const imagesBase64: string[] = [];
        for (const file of attachments) {
            const base64 = await toBase64(file);
            imagesBase64.push(base64 + '')
        }
        const model: PostCreateModel = {
            title: e.title,
            description: e.description,
            price: e.price,
            subCategoryId: selectedSubcategory.id,
            isActive: e.active,
            cityId: selectedCity.id,
            images: imagesBase64,
            subcategoriesAttributes: attributes
        }

        postStore.create(model)
            .then(() => props.history.goBack())
            .catch((e: AxiosError<{ message: string }>) => {
                if (e.response) {
                    setError(e.response?.data.message)
                }
                else {
                    setError('Something went wrong please try again.')
                }
            })
            .finally(() => setLoading(false))
    }

    const getSubcategories = () => {
        if (!selectedCategoryId) return [];
        const category = categories.find(e => e.id == selectedCategoryId);
        if (!category) return [];
        return category.subCategories;
    }

    const handleChangeSubcategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (!e.target.value || !selectedCategoryId) return;
        const category = categories.find(c => c.id == selectedCategoryId);
        if (!category) return;

        setAttributes([]);
        const subcategory = category.subCategories.find(s => s.id == e.target.value);
        setSelectedSubcategory(subcategory);
    }

    const handleSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !e.target.files.length) return;
        const file = e.target.files[0];
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg', 'image/webp'];
        if (!validImageTypes.includes(file['type'])) {
            // invalid file type code goes here.
            setError('We currenly only accept images (jpeg, gif, webp and png)')
            return;
        }
        var filesize = Number(((file.size / 1024) / 1024).toFixed(4)); // MB
        if (filesize > 5) {
            setError('The file cannot be larger than 5 MB')
            return;
        }
        if (attachments.length > 4) {
            setError('You can only attached 5 images maximun in a single message.');
            return;
        }
        setAttachements([...attachments, file]);
    }

    const handleRemoveImage = (file: File) => {
        setAttachements(attachments.filter(e => e != file))
    }

    return (
        <div
            style={{
                //display: 'flex',
                //flexDirection: 'row',
                //justifyContent: 'center',

                marginTop: 15
            }}
        >
            <ErrorModal
                message={error}
                onClose={() => setError('')}
            />
            <Box
                borderWidth={1}
                style={{
                    padding: 10,
                    maxWidth: 700,
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={FormSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => (
                        <Stack spacing={4}>

                            <Heading
                                size='sm'
                            >
                                Category *
                            </Heading>

                            <Select
                                placeholder="--- Select category ----"

                                onChange={e => setSelectedId(e.target.value)}
                            >
                                {categories.map(e => (
                                    <option
                                        key={e.id}
                                        value={e.id}
                                    >
                                        {e.name}
                                    </option>
                                ))}
                            </Select>

                            <Heading
                                size='sm'
                            >
                                Subcategory *
                            </Heading>

                            <Select
                                placeholder="--- Select subcategory ----"
                                onChange={handleChangeSubcategory}
                            >
                                {getSubcategories().map(e => (
                                    <option
                                        key={e.id}
                                        value={e.id}
                                    >
                                        {e.name}
                                    </option>
                                ))}
                            </Select>

                            <hr />

                            <Heading
                                size='sm'
                            >
                                Images
                            </Heading>

                            <PostFormImageSection
                                attachments={attachments}
                                onSelectImage={handleSelectImage}
                                onRemoveAttachments={handleRemoveImage}
                            />

                            <hr />

                            <FormInput
                                bg='white'
                                value={values.title}
                                error={errors.title}
                                placeholder='Ad title'
                                onValueChanged={(e) => setFieldValue('title', e)}
                            />
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    color="gray.300"
                                    fontSize="1.2em"
                                    children="$"
                                />
                                <Input
                                    placeholder="Price"
                                    value={values.price}
                                    error={errors.price}
                                    type='number'
                                    min={1}
                                    onChange={(e) => setFieldValue('price', e.target.value)}
                                />
                            </InputGroup>
                            {errors.price && (
                                <Text
                                    style={{
                                        color: 'red'
                                    }}
                                >
                                    {errors.price}
                                </Text>
                            )}
                            <FormTextArea
                                bg={'white'}
                                value={values.description}
                                error={errors.description}
                                placeholder='Description'
                                onValueChanged={(e) => setFieldValue('description', e)}
                            />
                            <Checkbox
                                defaultIsChecked
                                checked={values.active}
                                onChange={() => setFieldValue('active', !values.active)}
                            >
                                Active
                            </Checkbox>

                            <PostFormAttributeSection
                                attributes={attributes}
                                subcategory={selectedSubcategory}
                                onAttributesChanged={setAttributes}
                            />

                            <PostFormLocationSection
                                states={states}
                                selectedState={selectedState}
                                selectedCity={selectedCity}
                                onStateChanged={setSelectedState}
                                onCityChanged={setSelectedCity}
                            />

                            <Button
                                fontFamily={'heading'}
                                mt={8}
                                w={'full'}
                                bgGradient="linear(to-r, red.400,pink.400)"
                                color={'white'}
                                _hover={{
                                    bgGradient: 'linear(to-r, red.400,pink.400)',
                                    boxShadow: 'xl',
                                }}
                                disabled={loading}
                                isLoading={loading}
                                onClick={() => handleSubmit()}
                            >
                                Publish
                            </Button>
                        </Stack>
                    )}
                </Formik>

            </Box>
        </div>
    )

});
export default PostFormPage;
const styles = createStylesheet({
    attachmentsDiv: {
        padding: 10
    },
    attachementImg: {

    }
});
