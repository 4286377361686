import { Button } from "@chakra-ui/button";
import { Checkbox } from "@chakra-ui/checkbox";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/input";
import { Stack, Heading, Box } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { UserProfileModel, UserUpdateModel } from "src/models/user.model";
import ErrorModal from "../error.modal";
import FormInput from "../form/form.input";
import FormTextArea from "../form/form.textarea";
import PostFormAttributeSection from "../posts/form/post.form.attribute.section";
import PostFormImageSection from "../posts/form/post.form.image.section";
import PostFormLocationSection from "../posts/form/post.form.location.section";
import * as yup from 'yup';

type Props = {
    user: UserProfileModel,
    loading: boolean,
    onSubmit: (model: UserUpdateModel) => void;
}

const phoneRegExp = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/
const fieldRequired = 'This field is required';
const FormSchema = yup.object<Record<keyof UserUpdateModel, yup.AnySchema>>({
    firstName: yup.string().required(fieldRequired),
    lastName: yup.string().required(fieldRequired),
    email: yup.string().required(fieldRequired).email(),
    phone: yup.string()
        .required()
        .matches(phoneRegExp, 'Invalid phone number'),
    confirmPassword: yup.string().required(fieldRequired)
});

const ProfileUpdateForm = (props: Props) => {

    const { user } = props;

    const initialValues: UserUpdateModel = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        confirmPassword: ''
    }

    useEffect(() => {

        if (user) {
            initialValues.firstName = user.firstName;
            initialValues.lastName = user.lastName;
            initialValues.email = user.email;
            initialValues.phone = user.phone;
        }

    }, []);


    return (
        <div>

            <Box
                borderWidth={1}
                style={{
                    padding: 10,
                    maxWidth: 700,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 15
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={FormSchema}
                    onSubmit={props.onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => (
                        <Stack spacing={4}>

                            <Heading size='sm'>
                                First name*
                            </Heading>
                            <FormInput
                                bg='white'
                                value={values.firstName}
                                error={errors.firstName}
                                placeholder='First name'
                                onValueChanged={(e) => setFieldValue('firstName', e)}
                            />

                            <Heading size='sm'>
                                Last name*
                            </Heading>
                            <FormInput
                                bg='white'
                                value={values.lastName}
                                error={errors.lastName}
                                placeholder='Last name'
                                onValueChanged={(e) => setFieldValue('lastName', e)}
                            />

                            <Heading size='sm'>
                                Email*
                            </Heading>
                            <FormInput
                                bg='white'
                                value={values.email}
                                error={errors.email}
                                placeholder='Email'
                                onValueChanged={(e) => setFieldValue('email', e)}
                            />

                            <Heading size='sm'>
                                Phone number*
                            </Heading>
                            <FormInput
                                bg='white'
                                value={values.phone}
                                error={errors.phone}
                                placeholder='Phone number'
                                onValueChanged={(e) => setFieldValue('phone', e)}
                            />

                            <Heading size='sm'>
                                Confirm password*
                            </Heading>
                            <FormInput
                                bg='white'
                                value={values.confirmPassword}
                                error={errors.confirmPassword}
                                type='password'
                                placeholder='Confirm password'
                                onValueChanged={(e) => setFieldValue('confirmPassword', e)}
                            />

                            <Button
                                fontFamily={'heading'}
                                mt={8}
                                w={'full'}
                                bgGradient="linear(to-r, red.400,pink.400)"
                                color={'white'}
                                _hover={{
                                    bgGradient: 'linear(to-r, red.400,pink.400)',
                                    boxShadow: 'xl',
                                }}
                                disabled={props.loading}
                                isLoading={props.loading}
                                onClick={() => handleSubmit()}
                            >
                                Save changes
                            </Button>
                        </Stack>
                    )}
                </Formik>

            </Box>
        </div>
    )

};
export default ProfileUpdateForm;
