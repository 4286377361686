import { Flex, Tab, Table, TabList, TabPanel, TabPanels, Tabs, Tbody, Td, Tr, Text } from "@chakra-ui/react";
import { PostModel } from "src/models/post.model";

type Props = {
    post: PostModel
}


const PostDescription = (props: Props) => {

    const { post } = props;

    return (
        <div>
            <Flex
                alignItems="center"
                justifyContent="space-between"
                mx={2}
                borderWidth={0}
                overflowX="auto"
            >
                <Tabs defaultIndex={0} borderBottomColor="transparent">
                    <TabList>
                        <Tab py={4} m={0} _focus={{ boxShadow: "none" }}>
                            Description
                        </Tab>
                        <Tab py={4} m={0} _focus={{ boxShadow: "none" }}>
                            Other details
                        </Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <div>
                                <Text>
                                    {post.description}
                                </Text>
                            </div>
                        </TabPanel>

                        <TabPanel >
                            <Table>
                                <Tbody>
                                    {post.attributes && post.attributes.map(e => (
                                        <Tr>
                                            <Td style={{
                                                textTransform: 'capitalize',
                                                background: '#F4F4F4',
                                                fontSize: 14,
                                                textAlign: 'center'
                                            }}>
                                                <div>
                                                    {e.name}
                                                </div>
                                            </Td>
                                            <Td style={{ fontSize: 14 }}>
                                                {e.value}
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Flex>
        </div>
    )

};
export default PostDescription;
