import SubCategory from "src/store/subcategory";
import { Image, Text } from "@chakra-ui/react";
import { appStyles } from "src/utils/app.styles";

type Props = {
    subcategory: SubCategory,
    onClick: (subcategory: SubCategory) => void;
}

const SubcategoryCard = (props: Props) => {

    const { subcategory } = props;

    return (
        <div
            style={{
                width: 150,
                height: 180,
                cursor: "pointer"
            }}
            className="hover-underline"
            onClick={() => props.onClick(subcategory)}
        >
            <Image
                src={subcategory.images ? subcategory.images[0] : ""}
                width="100%"
                height="120px"
                objectFit="cover"
            />
            <Text
                class="ellipse two-lines"
                style={{ textAlign: "center" }}
            >
                {props.subcategory.name}
            </Text>
        </div>
    )

};
export default SubcategoryCard;
