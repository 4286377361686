import { Flex, FlexProps, Link, Stack } from "@chakra-ui/layout";
import { Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody } from "@chakra-ui/modal";
import { RadioGroup, Radio } from "@chakra-ui/radio";
import { Avatar, Icon, Text } from "@chakra-ui/react";
import { UserModel } from "src/models/user.model";
import { FcAdvertising, FcAddImage, FcSms, FcUpRight } from 'react-icons/fc'
import { IconType } from "react-icons";
import { ReactText } from "react";
import moment from "moment";


export type AppDrawerAction = 'my_ads' | 'post_ad' | 'messages' | 'logout' | 'profile';

type Props = {
    isOpened: boolean,
    user?: UserModel,
    onClose: () => void;
    onAction: (e: AppDrawerAction) => void;
}

interface LinkItemProps {
    name: string;
    icon: IconType;
    action: AppDrawerAction
}

const LinkItems: Array<LinkItemProps> = [
    { name: 'My ads', icon: FcAdvertising, action: 'my_ads' },
    { name: 'Post Ad', icon: FcAddImage, action: 'post_ad' },
    //{ name: 'Messages', icon: FcSms, action: 'messages' },
    { name: 'Logout', icon: FcUpRight, action: 'logout' },
];

const AppDrawer = (props: Props) => {

    const { user } = props;

    console.log('open:::', props.isOpened)

    if (!user) return <div></div>;

    return (
        <>
            <Drawer placement='left' onClose={props.onClose} isOpen={props.isOpened}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader borderBottomWidth="1px">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                cursor: 'pointer'
                            }}
                            onClick={() => props.onAction('profile')}
                        >
                            <Avatar
                                src={user.profile}
                                name={`${user.firstName} ${user.lastName}`}
                            />
                            <div>
                                <Text
                                    style={{
                                        marginLeft: 5
                                    }}
                                >
                                    {user.firstName} {user.lastName}
                                </Text>
                                <Text
                                    style={{
                                        fontSize: 12,
                                        color: 'gray',
                                        marginLeft: 5
                                    }}
                                >
                                    Joined {moment(user.dateCreated).fromNow()}
                                </Text>
                            </div>
                        </div>
                    </DrawerHeader>
                    <DrawerBody>
                        {LinkItems.map((link) => (
                            <NavItem
                                key={link.name}
                                icon={link.icon}
                                action={link.action}
                                onAction={props.onAction}
                            >
                                {link.name}
                            </NavItem>
                        ))}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )

};
export default AppDrawer;


interface NavItemProps {
    icon: IconType;
    children: ReactText;
    action: AppDrawerAction,
    onAction: (e: AppDrawerAction) => void;
}

const NavItem = ({ icon, children, onAction, action, ...rest }: NavItemProps) => {
    return (
        <Link href="#" style={{ textDecoration: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'blue.500',
                    color: '#fff',
                }}
                onClick={() => onAction(action)}
                {...rest}
            >
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="22"
                        _groupHover={{
                            color: 'red',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};