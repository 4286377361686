import { PropsWithChildren } from "react";
import { RouteComponentProps } from "react-router";
import { UserModel } from "src/models/user.model";


interface Props extends PropsWithChildren<{}>, RouteComponentProps<any> {
    user?: UserModel
}

const AuthenticatedView = (props: Props) => {

    if (!props.user) {
        props.history.push(`/signup?next=${props.location.pathname}`);
        return <></>;
    }

    return (
        <>
            {props.children}
        </>
    )
};
export default AuthenticatedView;
