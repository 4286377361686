import { Box, Button, Flex, Text, Textarea } from "@chakra-ui/react";
import { observer } from "mobx-react";
import PillPity from "pill-pity";
import { useEffect, useRef } from "react";
import { RouteChildrenProps, RouteComponentProps } from "react-router";
import ChatDesktop from "src/components/chats/chat.desktop";
import ChatMobile from "src/components/chats/chat.mobile";
import MobileNav from "src/components/mobile.nav";
import uiStore from "src/store/ui.store";
import { createStylesheet } from "src/utils/style";
import useWindowDimensions from "src/window.dimension";

interface Props extends RouteComponentProps {

}


const ChatPage = observer((props: Props) => {

    const { height, width } = useWindowDimensions();
    const { headerHeight } = uiStore;

    const messageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {

        uiStore.setShowFooter(false);

        chatScrollBottom();
        messageRef.current?.scrollIntoView({ behavior: 'smooth' });
        messageRef.current?.addEventListener('scroll', handleScroll);

       document.body.classList.add('no-scroll');

        return () => {
            uiStore.setShowFooter(true);
            document.body.classList.remove('no-scroll');
            messageRef.current?.removeEventListener("scroll", handleScroll);
        }

    }, []);

    const handleScroll = (e: any) => {
        //console.log('hello ', e.srcElement.scrollTop)
    }

    const chatScrollBottom = () => {
        messageRef.current?.scrollTo({
            top: messageRef.current.scrollHeight,
            behavior: 'smooth'
        })
    }

    const test = () => {
        chatScrollBottom()
    }


    return (
        width > 900 ? (
            <ChatDesktop />
        ) : (
            <ChatMobile
                router={props}
            />
        )
    )
});
export default ChatPage;

const styles = createStylesheet({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    messages: {
        overflowY: 'scroll'
    },
    section: {
        display: "flex",
    },
    sectionReceived: {
        display: "flex",
        justifyContent: "flex-end"
    },
    send: {
        background: "#fff",
        padding: 10,
        marginTop: 10,
        marginLeft: 10,
        maxWidth: 300
    },
    received: {
        background: "#DCF8C6",
        padding: 10,
        marginTop: 10,
        display: "flex",
    },
    inputDiv: {
        display: "flex",
        margin: 10,
        marginTop: 10,
        height: 100,
        padding: 10,
        background: "#fff"
    }
})
