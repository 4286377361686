import { Modal, Text, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Alert, AlertDescription, AlertIcon, AlertTitle, CloseButton } from "@chakra-ui/react";

type Props = {
    message: string,
    onClose: () => void;
}

const ErrorModal = (props: Props) => {


    return (
        <div>
            <Modal
                blockScrollOnMount={false}
                isOpen={props.message != ''}
                onClose={props.onClose}
            >
                <ModalOverlay />
                <ModalContent
                    style={{
                        background: '#FED7D7'
                    }}
                >
                    <ModalHeader>Error</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>
                            {props.message}
                        </Text>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={props.onClose}
                            background='red.500'
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )

};
export default ErrorModal;
