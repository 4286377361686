import apiService from "./api.service";
import { Pagination } from "../models/pagination";
import { ChatModel, MessageModel } from "../models/chat.model";

export default class ChatService {

    static get(page: number, limit: number) {
        return apiService.get<Pagination<ChatModel, {}>>(`chats`, {
            page,
            limit
        })
    }

    static fetch(chatId: string) {
        return apiService.get<ChatModel>(`chats/${chatId}`);
    }

    static createChat(postId: string, message: string) {
        return apiService.post<ChatModel>(`chats/${postId}`, {
            body: {
                message
            }
        })
    }

    static sendMessage(chatId: string, message: string) {
        return apiService.post<MessageModel>(`chats/${chatId}/messages`, {
            body: {
                message
            }
        })
    }

    static getMessages(chatId: string) {
        return apiService.get<MessageModel[]>(`chats/${chatId}/messages`);
    }

    static getUnreadMessages(chatId: string) {
        return apiService.get<MessageModel[]>(`chats/${chatId}/messages/unread`);
    }
};
